// active application
export const activeApp = process.env.VUE_APP_ACTIVE_APPLICATION || 'GNC';
// API-URL
export const apiUrl =
  activeApp === 'GNC'
    ? process.env.VUE_APP_API_URL_GNC
    : process.env.VUE_APP_API_URL_ETC;

// TOKEN PROPERTIES
export const tokenUpdateInterval =
  process.env.VUE_APP_TOKEN_UPDATE_INTERVALL || '10000';
export const minTokenTime =
  (process.env.VUE_APP_MIN_TOKEN_TIME || '3000') * 1000; // 3600 -> 1h
export const tokenTimespan = 1000 * 60 * 60; // 1h; can be used for testing different token timespans

// COOKIE CONSENT PROPERTIES
export const cookieConsentId = process.env.VUE_APP_COOKIE_CONSENT_ID;