import FoundationService from '../services/foundation.service.js';

const initState = { foundations: null, selectedFoundation: null };

export default {
  namespaced: true,
  state: initState,
  actions: {
    fetchFoundations({ commit, dispatch, state }) {
      return FoundationService.fetchFoundations().then(
        async (foundations) => {
          commit('setFoundations', foundations);
          if (!state.selectedFoundation) {
            // this is likely the first call to this function. Try to load a sensible default foundation.
            const lastFoundation = localStorage.getItem('storedFoundationId');
            if (lastFoundation) {
              await FoundationService.searchFoundations(lastFoundation).then(
                (results) => {
                  // the last used foundation might not be present in foundations currently loaded since the foundations use paging.
                  dispatch(
                    'updateSelectedFoundation',
                    (results?.length ? results : foundations)[0]
                  ); // use the first result of results as the default foundation, or the first available foundation if none was found.
                }
              );
            } else {
              await dispatch(
                'updateSelectedFoundation',
                foundations.length ? foundations[0] : null
              );
            }
          }
          return Promise.resolve(foundations);
        },
        (error) => {
          dispatch('reset');
          return Promise.reject(error);
        }
      );
    },
    updateSelectedFoundation({ commit, dispatch }, foundation) {
      if (foundation) {
        commit('updateSelectedFoundation', foundation);
        localStorage.setItem('storedFoundationId', foundation.trid);
        dispatch('ticket/reset', null, { root: true });
      }
    },

    reset({ commit }) {
      commit('RESET_FOUNDATION');
    }
  },
  mutations: {
    setFoundations(state, foundations) {
      state.foundations = foundations;
    },
    updateSelectedFoundation(state, selectedFoundation) {
      state.selectedFoundation = selectedFoundation;
    },
    setFirstFoundationCounter(state, counter) {
      state.firstFoundationCounter = counter;
    },
    RESET_FOUNDATION(state) {
      Object.assign(state, initState);
    }
  }
};
