/**
 * function to debounce other functions, ensures that func is not called too often.
 * @param {*} func the function to debounce
 * @param {*} timeout the timeout after which the next call can be executed
 * @param {*} immediate if truthy, executes the function immediately at the first function call and suppresses subsequent calls within
 * the given timeout. Else executes the function after the timeout has passed without subsequent calls to the function.
 * @returns the debounced function
 */
export default function debounce(func, timeout = 300, immediate) {
  let timer;
  const self = this;
  return (...args) => {
    if (!timeout || timeout < 0) {
      func.apply(self, args);
      return;
    }
    if (immediate && !timer) {
      func.apply(self, args);
    }
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (immediate) {
        timer = null;
      } else {
        func.apply(self, args);
      }
    }, timeout);
  };
}
