<template>
  <div
    class="container-fluid align-items-center bg-white subheader border-bottom ps-0">
    <div
      v-if="hasRole('PORTAL_USER')"
      id="subNavigation"
      class="d-flex flex-grow-1 justify-content-start">
      <div
        v-if="showOnRoutes(salesPartnerRoutes)"
        class="subheader-salespartner-routes">
        <router-link :to="{ name: 'customerOverview' }" class="nav-link">
          <SubHeaderNavigationButton
            icon="usersSecond"
            text="Kunden"
            :active="
              currentRouteName === 'customerOverview' ||
              currentRouteName === 'customerForm'
            " />
        </router-link>
        <router-link :to="{ name: 'draftOverview' }" class="nav-link">
          <SubHeaderNavigationButton
            icon="deskphone"
            text="Entwürfe"
            :active="
              currentRouteName === 'draftOverview' ||
              currentRouteName === 'draftForm'
            " />
        </router-link>
        <router-link :to="{ name: 'orderOverview' }" class="nav-link">
          <SubHeaderNavigationButton
            icon="deskphone"
            text="Bestellungen"
            :active="
              currentRouteName === 'orderOverview' ||
              currentRouteName === 'orderForm'
            " />
        </router-link>
        <router-link to="/#" class="nav-link">
          <SubHeaderNavigationButton icon="deskphone" text="Dokumente" />
        </router-link>
      </div>
      <div
        v-if="showOnRoutes(wholeSaleRoutes)"
        class="subheader-salespartner-routes">
        <router-link :to="{ name: 'wholeSaleSearch' }" class="nav-link">
          <SubHeaderNavigationButton
            icon="search"
            text="Suche"
            :active="currentRouteName === 'wholeSaleSearch'" />
        </router-link>
        <router-link
          :to="{ name: 'wholeSaleCustomerOverview' }"
          class="nav-link">
          <SubHeaderNavigationButton
            icon="usersSecond"
            text="Großhandelskunden"
            :active="
              currentRouteName === 'wholeSaleCustomerOverview' ||
              currentRouteName === 'wholeSaleCustomerServices' ||
              currentRouteName === 'wholeSaleSipOverview' ||
              currentRouteName === 'wholeSaleSipCreateNumberForm' ||
              currentRouteName === 'wholeSalePortNumberForm' ||
              currentRouteName === 'wholeSaleCustomerAdd' ||
              currentRouteName === 'wholeSaleBackupRouting' ||
              currentRouteName === 'wholeSaleCustomerServiceAdd'
            " />
        </router-link>
        <router-link
          :to="{ name: 'wholeSaleCustomerProcesses' }"
          class="nav-link">
          <SubHeaderNavigationButton
            icon="deskphone"
            text="Prozesse"
            :active="currentRouteName === 'wholeSaleCustomerProcesses'" />
        </router-link>
      </div>
    </div>
    <div
      v-else
      id="subNavigation"
      class="d-flex flex-grow-1 justify-content-start"></div>
    <div
      v-if="currentUser"
      class="align-items-center d-flex my-2 justify-content-start">
      <CustomButton
        :title="$t('subheader.help')"
        icon="search"
        icon-color="black"
        icon-class="icon-size-18"
        additional-classes="btn-subheader"
        target="/help" />
    </div>
    <div class="align-items-center d-flex my-2">
      <a :href="url_imprit" target="_blank" style="white-space: nowrap">
        <CustomButton
          :title="$t('subheader.imprint')"
          icon="info"
          icon-color="black"
          icon-class="icon-size-18"
          additional-classes="btn-subheader" />
      </a>
    </div>
    <div class="align-items-center d-flex my-2">
      <a :href="url_privacyPolicy" target="_blank" style="white-space: nowrap">
        <CustomButton
          :title="$t('subheader.privacyPolicy')"
          icon="lock"
          icon-color="black"
          icon-class="icon-size-18"
          additional-classes="btn-subheader" />
      </a>
    </div>
  </div>
</template>

<script>
import CustomButton from '@/components/elements/CustomButton.vue';
import AuthService from '@/services/auth.service.js';
import SubHeaderNavigationButton from '../elements/SubHeaderNavigationButton.vue';

export default {
  name: 'SubHeader',
  components: {
    SubHeaderNavigationButton,
    CustomButton
  },
  props: ['role'],
  data() {
    return {
      url_imprit: '',
      url_privacyPolicy: '',
      salesPartnerRoutes: [
        'customerOverview',
        'customerForm',
        'draftOverview',
        'orderOverview',
        'draftForm',
        'orderForm'
      ],
      wholeSaleRoutes: [
        'wholeSaleCustomerOverview',
        'wholeSaleSearch',
        'wholeSaleCustomerProcesses',
        'wholeSaleCustomerServices',
        'wholeSaleCustomerServices',
        'wholeSaleSipOverview',
        'wholeSaleSipOverview',
        'wholeSaleSipCreateNumberForm',
        'wholeSalePortNumberForm',
        'wholeSaleCustomerAdd',
        'wholeSaleCustomerServiceAdd',
        'wholeSaleBackupRouting'
      ]
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  mounted() {
    this.url_imprit = process.env.VUE_APP_URL_IMPRINT;
    this.url_privacyPolicy = process.env.VUE_APP_URL_PRIVACYPOLICY;
  },
  methods: {
    showOnRoutes(routes) {
      return routes.includes(this.currentRouteName);
    },
    hasRole(allowedRoles) {
      return this.$store.getters['auth/hasRole'](allowedRoles);
    }
  }
};
</script>

<style lang="scss" scoped>
.subheader {
  display: flex;
  align-items: center;
}

.subheader-salespartner-routes {
  display: flex;
  align-items: center;
}

.nav-link {
  padding: 0;
}
</style>
