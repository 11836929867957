import WholeSaleCustomerService from '@/services/wholeSaleCustomer.service.js';

const initState = { wholeSaleCustomers: null, selectedWholeSaleCustomer: null };

export default {
  namespaced: true,
  state: initState,
  actions: {
    getWholeSaleCustomers({ commit, dispatch }, payload = {}) {
      const { searchString } = payload;
      const selectedWholeSaleCutomerId = payload.selectedWholeSaleCustomerId;

      return WholeSaleCustomerService.getWholeSaleCustomerList(
        searchString,
        selectedWholeSaleCutomerId
      ).then(
        (wholeSaleCustomers) => {
          commit('SET_CUSTOMERS', wholeSaleCustomers);
          return Promise.resolve(wholeSaleCustomers);
        },
        (error) => {
          dispatch('reset');
          return Promise.resolve(error);
        }
      );
    },
    setSelectedWholeSaleCustomer({ commit, state }, customerId) {
      const selectedWholeSaleCustomer = state.wholeSaleCustomers.find(
        (cust) => cust.sipid === customerId
      );

      commit('SET_SELECTED_CUSTOMER', selectedWholeSaleCustomer);
    },
    reset({ commit }) {
      commit('RESET_CUSTOMER');
    }
  },
  mutations: {
    SET_CUSTOMERS(state, wholeSaleCustomers) {
      state.wholeSaleCustomers = wholeSaleCustomers;
    },
    SET_SELECTED_CUSTOMER(state, selectedWholeSaleCustomer) {
      state.selectedWholeSaleCustomer = selectedWholeSaleCustomer;
    },
    RESET_CUSTOMER(state) {
      Object.assign(state, initState);
    }
  }
};
