import { sendAjaxRequest, requestError } from '@/services/ajax.service.js';

let store;
export function init(globalStore) {
  store = globalStore;
}
class OrderService {
  getOrderList(parameter = {}) {
    const data = { search: '', type: 'hfo.vp.order' };

    if (parameter.selectOrderId) {
      data._selected = parameter.selectOrderId;
    }

    if (parameter.searchString) {
      data.search += parameter.searchString;
    }

    const foundationId = store.state.foundation.selectedFoundation.trid;

    return sendAjaxRequest(
      `/foundation/${foundationId}/data`,
      'GET',
      data
    ).then(
      (res) => {
        if (res._error) {
          throw requestError(res);
        } else {
          return res;
        }
      },
      (error) => error
    );
  }
}

export default new OrderService();
