import { sendAjaxRequest } from '@/services/ajax.service.js';
import { apiUrl } from '@/const.js';
import dataHelper from '@/helper/dataHelper.js';
import OneTimeActionService from './oneTimeActionService.js';

const otaDownloadUrl = 'public/filedownload';

class FileService {
  async getRepoList(url) {
    return sendAjaxRequest(url, 'GET');
  }

  getCommissionStatementList(url, payload) {
    return sendAjaxRequest(url, 'GET', payload);
  }

  getFileDownloadLink(path) {
    const payload = {
      path: decodeURIComponent(path) // will be re-encoded in backend, causing the links to be the same again.
    };
    const file = path.substring(path.lastIndexOf('/') + 1, path.length);
    const rest = apiUrl + (apiUrl.endsWith('/') ? '' : '/');
    return new OneTimeActionService()
      .create('downloadFile', payload)
      .then((ret) => ({
        token: ret.token,
        link: `${rest + otaDownloadUrl}/${file}?token=${ret.token}`
      }));
  }

  getArchiveDownloadLink(documentReference) {
    const rest = apiUrl + (apiUrl.endsWith('/') ? '' : '/');
    return new OneTimeActionService()
      .create('downloadArchiveFile', documentReference)
      .then((ret) => ({
        token: ret.token,
        link: `${`${rest}public/archive/${documentReference._attachment}`}?token=${
          ret.token
        }`
      }));
  }

  /**
   * directly downloads a filename in the same window with a filename of your choosing
   * @param {string} url url pointing to a file directly
   * @param {string} filename a filename of your choosing with file extension, by default just grabs last two strings separated by dot
   */
  downloadFile(url, filename) {
    console.log(filename);

    fetch(url, {
      method: 'GET'
    })
      .then((resp) => resp.blob())
      .then((blob) => {
        const objurl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = objurl;
        a.download = filename; // the filename you want
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
  }

  /**
   * this function is used to modify a given file name with
   * a month in it to prepare it for a sorting function
   * @param {String} name
   * @returns a string, that can be used in a sorting function
   */
  getSortableFileName(name) {
    if (!name) return name;
    // eslint-disable-next-line no-param-reassign
    name = name.toLowerCase();
    if (name.indexOf('januar') !== -1) return `l${name}`;
    if (name.indexOf('februar') !== -1) return `k${name}`;
    if (name.indexOf('märz') !== -1 || name.indexOf('maerz') !== -1)
      return `j${name}`;
    if (name.indexOf('april') !== -1) return `i${name}`;
    if (name.indexOf('mai') !== -1) return `h${name}`;
    if (name.indexOf('juni') !== -1) return `g${name}`;
    if (name.indexOf('juli') !== -1) return `f${name}`;
    if (name.indexOf('august') !== -1) return `e${name}`;
    if (name.indexOf('september') !== -1) return `d${name}`;
    if (name.indexOf('oktober') !== -1) return `c${name}`;
    if (name.indexOf('november') !== -1) return `b${name}`;
    if (name.indexOf('dezember') !== -1) return `a${name}`;
    return name;
  }

  /**
   * Processes the fle/folder/repo lists
   * @param data the response of FileService.getRepoList(url)
   * @param order the order in which the files are sorted, "file:newest|file:oldest"
   * @returns an object { fileList[], folderList[], repositoryList[]} or null if no valid sorting is provided
   */
  async getFolderFilesRepoLists(data, order = 'file:newest') {
    // treat the order parameter like an enum
    const validSortings = ['file:oldest', 'file:newest'];
    if (!validSortings.includes(order)) {
      console.error('no valid sorting mode provided!');
      return null;
    }

    const folderList = [];
    const fileList = [];
    const repositoryList = [];

    console.log(`DATA: ${JSON.stringify(data)}`);
    if (data.results) {
      for (let k = 0; k < data.results.length; k++) {
        const d = data.results[k];
        switch (d.type) {
          case 'repository':
            repositoryList.push(d);
            break;
          case 'folder':
            folderList.push(d);
            break;
          default:
            // force different order by prepending zzz
            d.sort = `zzz${d.name}`;
            if (
              d.description &&
              d.description.length > 0 &&
              d.description.substr(0, 1) == '#'
            ) {
              const lines = d.description.split('\n');
              while (
                lines.length > 0 &&
                lines[0].length > 0 &&
                lines[0].substr(0, 1) == '#'
              ) {
                const instr = lines[0].substr(1).split(':');
                switch (instr[0]) {
                  case 'sort':
                    d.sort = instr[1].trim();
                    break;
                }
                lines.splice(0, 1);
              }
              d.description = lines.join('\n');
            }
            // files receive a download url, downlaod name and icon
            if (d && d.fileurl) {
              let url = d.fileurl;
              let query = '';
              const p = url.indexOf('?');
              if (p > 0) {
                query = url.substr(p);
                url = url.substr(0, p);
              }
              url = `${url}/${encodeURIComponent(d.name)}${query}`;
              d.fileurl = url;
              url = `#!f:${encodeURIComponent(`${this.subPath}/${d.name}`)}`;
              d.browserUrl = url;
            }
            d.icon = dataHelper.getFileTypeFromName(d.name);
            d.download_name = decodeURIComponent(d.name);
            if (d.sort) d.sort = this.getSortableFileName(d.sort);
            fileList.push(d);
        }
      }
    }

    let sortFunc = (l, r) => {
      if (l.sort === r.sort) {
        return 0;
      }
      if (l.sort < r.sort) {
        return 1;
      }
      return -1;
    };

    if (order.indexOf('file:oldest') > -1) {
      sortFunc = (l, r) => {
        if (l.sort === r.sort) {
          return 0;
        }
        if (l.sort < r.sort) {
          return -1;
        }
        return 1;
      };
    }

    fileList.sort(sortFunc);

    folderList.sort((l, r) => {
      const n1 = Number(l.name);
      const n2 = Number(r.name);
      // if left and right names are numbers, sort highest to lowest
      if (n1 && n2) {
        return n2 - n1;
      }
      // always prioritize numbers over normal strings
      if (n1 && !n2) {
        return -1;
      }
      if (n2 && !n1) {
        return 1;
      }

      // handle normal names
      const lname = this.getSortableFileName(l.name);
      const rname = this.getSortableFileName(r.name);

      if (lname === rname) {
        return 0;
      }
      if (lname < rname) {
        return -1;
      }
      return 1;
    });

    return {
      folderList,
      fileList,
      repositoryList
    };
  }
}

export default new FileService();
