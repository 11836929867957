import { sendAjaxRequest, requestError } from '@/services/ajax.service.js';

let store;
export function init(globalStore) {
  store = globalStore;
}

class WholeSaleCustomerService {
  getWholeSaleCustomerList(searchString = '', selectedWholeSaleCutomerId = '') {
    const foundationId = store.state.foundation.selectedFoundation.trid;
    const data = {
      search: searchString,
      _selected: selectedWholeSaleCutomerId,
      _sync: true,
      _action: 'numbers'
    };
    return sendAjaxRequest(
      `/foundation/${foundationId}/v3/customer`,
      'POST',
      data
    ).then(
      (res) => {
        if (res._error) {
          throw requestError(res);
        } else {
          return res;
        }
      },
      (error) => error
    );
  }
}

export default new WholeSaleCustomerService();
