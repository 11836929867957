<template>
  <header>
    <nav class="navbar navbar-expand-xl bg-primary">
      <div class="container-fluid text-nowrap">
        <router-link to="/" class="navbar-brand">
          <IconComponent name="logoGammaWhite" class="mx-3 my-1 logo-size" />
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarToggler"
          aria-controls="navbarToggler"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div id="navbarToggler" class="collapse navbar-collapse">
          <ul v-if="currentUser" class="navbar-nav me-auto">
            <li class="nav-item">
              <router-link to="/customer/profile" class="nav-link">
                <IconComponent
                  name="usercheck"
                  class="mx-2 usercheck-icon-size"
                  icon-color="white" />
                {{ currentUser._user }}
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/my-portal" exact class="nav-link py-3 px-4">
                {{ $t('header.navigation.myPortal') }}
              </router-link>
            </li>

            <li v-if="hasRole('SP')" class="nav-item">
              <router-link
                :to="{ name: 'customerOverview' }"
                class="nav-link py-3 px-4">
                {{ $t('header.navigation.salesPartners') }}
              </router-link>
            </li>
            <li v-if="hasRole('SP')" class="nav-item">
              <router-link to="/tutorials" class="nav-link py-3 px-4">
                {{ $t('header.navigation.tutorials') }}
              </router-link>
            </li>
            <li v-if="hasRole('SP')" class="nav-item">
              <router-link to="/documents" class="nav-link py-3 px-4">
                {{ $t('header.navigation.documents') }}
              </router-link>
            </li>
            <li v-if="hasRole('WS')" class="nav-item">
              <router-link
                :to="{ name: 'wholeSaleSearch' }"
                class="nav-link py-3 px-4">
                {{ $t('header.navigation.wholesale') }}
              </router-link>
            </li>

            <li class="nav-item">
              <a class="nav-link py-3 px-4" href @click.prevent="logOut">
                {{ $t('header.navigation.logout') }}
              </a>
            </li>
          </ul>

          <ul v-if="!currentUser" class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <router-link to="/" class="nav-link py-3 px-4">
                {{ $t('header.navigation.login') }}
              </router-link>
            </li>
          </ul>

          <div
            v-if="currentUser"
            class="align-items-center d-flex position-relative flex-grow-1">
            <div
              class="search input-group ms-auto me-auto me-xl-0 my-2 my-xl-0">
              <!--
              <input type="text" class="form-control border-end-0" v-model="searchInput" v-on:keyup.enter="search"
                     :placeholder="$t('header.search')">
              <div class="input-group-text bg-white search-button" v-on:click="search">
                <IconComponent name="search" class="search-icon"/>
              </div>
              -->
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import IconComponent from '@/components/utility/Icon.vue';
import AuthService from '@/services/auth.service.js';

export default {
  name: 'Header',
  components: {
    IconComponent
  },
  data() {
    return {
      searchInput: ''
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    search() {
      if (this.searchInput.trim() == '') return;
      try {
        this.$router.push({
          name: 'search',
          query: { search: this.searchInput }
        });
      } catch (error) {
        console.log(JSON.stringify(error));
      }
    },
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/');
    },
    hasRole(allowedRoles) {
      return this.$store.getters['auth/hasRole'](allowedRoles);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/custom.scss';

header {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 800;
  font-size: 12.5px;

  .navbar {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .nav-link {
    color: #fff;
    font-weight: 600;
    height: 100%;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    &.router-link-active {
      background-color: $secondary;
      color: $white;

      &:hover {
        color: $black;
      }
    }

    &:hover {
      background-color: $light;

      > svg {
        fill: $black;
      }
    }
  }

  .search {
    max-width: 400px;

    input:focus {
      box-shadow: none;
      border-color: $gray-300;
    }

    .search-button {
      cursor: pointer;
    }
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .search-icon {
    width: 19px;
    height: auto;
  }

  .logo-size {
    width: 96px;
    height: auto;
  }

  .usercheck-icon-size {
    width: 22px;
    height: auto;
  }
}
</style>
