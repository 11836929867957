import { sendAjaxRequest, requestError } from '@/services/ajax.service.js';

let store;
export function init(globalStore) {
  store = globalStore;
}

class TicketService {
  getTicketList(parameter = {}) {
    const data = { search: '', type: 'hd.ticket' };

    if (parameter.selectTicketId) {
      data._selected = parameter.selectTicketId;
    }

    if (parameter.searchString) {
      data.search += parameter.searchString;
    }

    const foundationId = store.state.foundation.selectedFoundation.trid;

    return sendAjaxRequest(
      `/foundation/${foundationId}/data`,
      'GET',
      data
    ).then(
      (res) => {
        if (res._error) {
          throw requestError(res);
        } else {
          return res;
        }
      },
      (error) => error
    );
  }

  getTicket(ticketId) {
    const foundationId = store.state.foundation.selectedFoundation.trid;

    const data = { _: Date.now() };

    return sendAjaxRequest(
      `/foundation/${foundationId}/data/${ticketId}`,
      'GET',
      data
    ).then(
      (res) => {
        if (res._error) {
          throw requestError(res);
        } else {
          return res;
        }
      },
      (error) => error
    );
  }

  getTicketAttachments(ticketForeignId) {
    const foundationId = store.state.foundation.selectedFoundation.trid;

    const data = { _: Date.now(), _action: 'list' };

    return sendAjaxRequest(
      `/public/filebrowser/home${foundationId}_${ticketForeignId}:`,
      'GET',
      data
    ).then(
      (res) => {
        if (res._error || !res.results) {
          throw requestError(res);
        } else {
          res.results.sort((a, b) => {
            let r = (b.creationdate - a.creationdate) / 1000;
            if (r === 0) r = a.name.localeCompare(b.name);
            return res;
          });
          return res.results;
        }
      },
      (error) => error
    );
  }

  addNote(ticketId, note) {
    const foundationId = store.state.foundation.selectedFoundation.trid;

    const data = { note, _action: 'append' };

    return sendAjaxRequest(
      `/foundation/${foundationId}/data/${ticketId}`,
      'POST',
      data
    ).then(
      (res) => {
        if (res._error) {
          throw requestError(res);
        } else {
          return res;
        }
      },
      (error) => error
    );
  }

  reopenTicket(ticketId, reason) {
    const foundationId = store.state.foundation.selectedFoundation.trid;

    const data = { _action: 'reopen', reason };

    return sendAjaxRequest(
      `/foundation/${foundationId}/data/${ticketId}`,
      'POST',
      data
    ).then(
      (res) => {
        if (res._error) {
          throw requestError(res);
        } else {
          return res;
        }
      },
      (error) => error
    );
  }

  createTicket(payload) {
    return sendAjaxRequest(
      `/foundation/${store.state.foundation.selectedFoundation.trid}/data`,
      'POST',
      payload
    ).then(
      (res) => {
        if (res._error) {
          return res;
        }
        return res;
      },
      (error) => error
    );
  }

  /**
   * Attaches a file from the filequeue to a ticket
   * @param {*} ticketId
   * @param {*} queueFile
   */
  attachFile(ticketId, queue) {
    const url = `/foundation/${store.state.foundation.selectedFoundation.trid}/data/${ticketId}`;
    const data = {
      _action: 'attach',
      fileQueue: queue
    };
    return sendAjaxRequest(url, 'POST', data);
  }
}

export default new TicketService();
