<template>
  <div id="app">
    <Loader id="main-loader" ref="mainLoader" :loader-fixed="true">
      <header-content-gnc v-if="activeApp === 'GNC'" class="header" />
      <header-content-etc v-else class="header" />
      <router-view class="content" />
      <portal-footer class="footer">
        <template #left>
          {{ getCompany }}
        </template>
        <template #right>
          {{ $t('footer.right.hotline') }}
          <a :href="'tel:' + getHotline">
            {{ getHotline }}
          </a>
          {{ $t('footer.right.openingHours') }}
        </template>
      </portal-footer>
    </Loader>
  </div>
</template>

<script>
import EventBus from '@/helper/eventBus.js';
import PortalFooter from '@/components/layout/PortalFooter.vue';
import { activeApp, cookieConsentId } from './const.js';
import Loader from './components/utility/Loader.vue';

const favicon = require('@/assets/images/png/favicon.png');

export default {
  components: { Loader, PortalFooter },
  data() {
    return {
      activeApp
    };
  },
  metaInfo() {
    return {
      htmlAttrs: {
        lang: 'de-DE'
      },
      title: this.getSelectedFoundationTitle,
      link: [
        {
          rel: 'icon',
          href: favicon
        }
      ],
      meta: [
        { charset: 'utf-8' },
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1'
        }
      ]
    };
  },
  computed: {
    getSelectedFoundationTitle() {
      let selectedFoundationTitle = 'Gamma';
      if (activeApp === 'ETC') {
        selectedFoundationTitle = 'Epsilon';
      }
      if (this.$store.state.foundation?.selectedFoundation?.title) {
        selectedFoundationTitle += ` - ${this.$store.state.foundation.selectedFoundation.title}`;
        return selectedFoundationTitle;
      }
      if (this.$store.state.foundation?.selectedFoundation?.trid) {
        selectedFoundationTitle += ` - ${this.$store.state.foundation.selectedFoundation.trid}`;
        return selectedFoundationTitle;
      }
      return selectedFoundationTitle;
    },
    getHotline() {
      return activeApp === 'GNC'
        ? this.$t('footer.right.gnc.phoneNumber')
        : this.$t('footer.right.etc.phoneNumber');
    },
    getCompany() {
      return activeApp === 'GNC'
        ? '© 2022 Gamma Communications GmbH'
        : '© 2022 Epsilon Communications GmbH';
    }
  },
  mounted() {
    EventBus.$on('showMainLoader', (state) => {
      this.$refs.mainLoader.setLoading(state);
    });
    window.usercentrics = {
      settingsId: cookieConsentId
    };
    window.usercentrics = {
      paths: {
        bundle: 'https://app.usercentrics.eu/latest/bundle.js'
      },
      settingsIds: cookieConsentId,
      settingsVersionOverwrite: 'preview'
    };
    const script = document.createElement('script');
    script.setAttribute('type', 'application/javascript');
    script.setAttribute(
      'src',
      'https://app.usercentrics.eu/browser-ui/latest/loader.js'
    );
    script.id = 'usercentrics-cmp';
    script.setAttribute('data-settings-id', cookieConsentId);

    document.head.appendChild(script);
  }
};
</script>

<style lang="scss" scoped>
@import 'assets/scss/variables.scss';

@font-face {
  font-family: 'Mulish';
  src: local('Mulish'),
    url(./assets/fonts/Mulish/Mulish-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato';
  src: local('Lato'),
    url(./assets/fonts/Lato/Lato-Regular.ttf) format('truetype');
}

#main-loader {
  //ensure flex layout for content is kept, remove drop shadow for cleaner look
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
  :deep(.loader-container) {
    //break out of scoped scss in order to change loader-container shadows for this view only
    box-shadow: none;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
  }
}

body {
  min-height: 100vh;
}

#app {
  min-height: 100vh;
}

.content {
  min-height: 80vh;
}

.footer {
  margin-top: 50px;
  flex-shrink: 0;
}
</style>
