<template>
  <!-- This will be used to display the files in the portal search if the display is set to "View as List".-->
  <!-- This is the complement to the FileBrowserBox used in the same Search View-->
  <div
    v-if="file.properties.shortcuturl === ''"
    class="searchlist-element"
    @click="download(file)">
    <div class="col-8 d-flex align-items-center filename-container">
      <i :class="['bi', 'bi-filetype-' + file.icon, 'pe-2', 'fs-3']"></i>
      <strong>{{ $dataHelper.getProcessedFileName(file.name) }}</strong>
    </div>

    <div class="col-2 filesize-text">
      {{
        file.version
          ? 'Version: ' + file.version
          : $dataHelper.getFileSize(file.size)
      }}
    </div>

    <div class="col-1 download-hint">
      <i class="bi bi-download pe-2"></i>
    </div>
  </div>
  <div v-else class="searchlist-element">
    <div class="col-9 d-flex">
      <i :class="['bi', 'bi-link', 'pe-2', 'fs-3']"></i>
      <strong>{{ file.name }}</strong>
    </div>
  </div>
</template>
<script>
import DocumentService from '@/services/document.service.js';

export default {
  name: 'SearchListElement',
  props: {
    file: {
      type: Object,
      required: true
    }
  },
  methods: {
    download(file) {
      DocumentService.downloadFile(file);
    }
  }
};
</script>
<style lang="scss" scoped>
@import '../../../assets/scss/variables';

.searchlist-element {
  width: 100%;

  // strong-tag for increasing the specificity => no "!important" needed
  .filename-container strong {
    // ellipsis for hiding the sometimes gigantic filenames
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 4px;
    display: block;
  }

  .filesize-text {
    margin-left: auto;
    text-align: end;
  }
}

.hover-style {
  cursor: pointer;

  &:hover {
    background-color: $secondary;
    color: $white;
  }
}
</style>
