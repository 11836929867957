<template>
  <div id="loginPage">
    <SubHeader />

    <div class="container-fluid">
      <div class="row justify-content-center my-5">
        <div class="col-auto my-3">
          <form name="form" @submit.prevent="handleLogin">
            <div class="form-group">
              <label for="username" class="mb-3">
                {{ $t('login.username') }}
              </label>
              <input
                v-model="user.username"
                v-validate="'required'"
                type="text"
                class="form-control"
                name="username"
                autocomplete="username" />
              <div
                v-if="errors.has('username')"
                class="alert alert-danger"
                role="alert">
                Geben Sie bitte eine Benutzerkennung ein.
              </div>
            </div>
            <div class="form-group">
              <label for="password" class="mb-3 mt-4">
                {{ $t('login.password') }}
              </label>
              <input
                v-model="user.password"
                v-validate="'required'"
                type="password"
                class="form-control"
                name="password"
                autocomplete="current-password" />
              <div
                v-if="errors.has('password')"
                class="alert alert-danger"
                role="alert">
                Geben Sie bitte ein Passwort ein.
              </div>
            </div>
            <div class="form-group text-center my-4">
              <CustomButton
                :title="$t('login.loginBtn')"
                btn-color-class="btn-secondary px-5"
                :disabled="loading" />
            </div>
          </form>
          <div class="row my-4">
            <div class="col-12">
              <router-link
                to="/reset-password"
                class="mt-3 text-decoration-none">
                <Icon name="questionMarkRound" class="secondary icon-size-24" />
                {{ $t('login.resetPassword') }}
              </router-link>
            </div>
          </div>
          <div class="row my-3 customer-service-container">
            <div class="col-12">
              <p
                class="mb-0 customer-service-header"
                v-html="$t('login.customerServiceHeader')"></p>
              <span
                v-if="environment === 'ETC'"
                v-html="$t('etc.login.customerServiceBody')"></span>
              <span v-else v-html="$t('login.customerServiceBody')"></span>
            </div>
          </div>
          <div class="row my-3 link-container">
            <div class="col-auto mr-2">
              <form name="form" @submit.prevent="handleBilling">
                <CustomButton
                  v-if="environment === 'GNC'"
                  icon="eye"
                  icon-class="icon-size-20 me-2"
                  :title="$t('login.invoicePortalBtn')" />
              </form>
            </div>
            <div class="col-auto mx-2">
              <a
                v-if="environment === 'GNC'"
                href="http://config.hochfranken-online.de/user/index.php">
                <CustomButton
                  icon="eye"
                  icon-class="icon-size-20 me-2"
                  :title="$t('login.confixxWebportalBtn')" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from '@/components/elements/CustomButton.vue';
import { handleRedirect } from '@/services/router.service.js';
import dataHelper from '@/helper/dataHelper.js';
import User from '@/models/user.js';
import SubHeader from '@/components/layout/SubHeader.vue';
import Icon from '@/components/utility/Icon.vue';

export default {
  name: 'Login',

  components: {
    CustomButton,
    SubHeader,
    Icon
  },
  data() {
    return {
      user: new User(
        process.env.VUE_APP_DEFAULT_USER,
        process.env.VUE_APP_DEFAULT_PASS,
        '',
        ''
      ),
      loading: false,
      environment: process.env.VUE_APP_ACTIVE_APPLICATION,
      pathHomeButton: process.env.VUE_APP_DEFAULT_PATH,
      error: false,
      billingPortalUrl: 'https://rechnung.hfo-telecom.de',
      billingPortalLink: ''
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  methods: {
    handleLogin() {
      this.loading = true;
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }

        if (this.user.username && this.user.password) {
          this.$store.dispatch('auth/login', this.user).then(
            (res) => {
              if (res._NEEDTERMSOFUSE) {
                this.$router.push('/terms-of-use');
              } else {
                // login successful, go to portal and
                if (!handleRedirect(this.$route, '/my-portal')) {
                  this.$toastr.w(
                    `Navigation zu ${dataHelper.escapeHtml(
                      this.$route.query.redirect
                    )} nicht möglich`,
                    'Weiterleitung verhindert'
                  );
                }
                this.loading = false;
                this.$toastr.s('Erfolgreich eingeloggt!');
              }
            },
            (error) => {
              this.loading = false;
              this.$swal.fire({
                icon: 'error',
                title: error.status,
                text: 'Passwort/Nutzername ungültig'
              });
            }
          );
        }
      });
    },
    handleBilling() {
      this.$swal.fire({
        title: 'Kundennummer:',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Einloggen',
        showLoaderOnConfirm: true,
        preConfirm: (userNumber) => {
          const regExp = new RegExp('^\\d+$');
          if (!regExp.test(userNumber)) {
            console.log('userNumber is not a number');
            this.$swal.fire({
              icon: 'error',
              text: 'Kundennummer darf nur Ziffern enthalten'
            });
            return;
          }
          this.billingPortalLink = this.billingUrl(userNumber);
          if (!this.billingPortalLink) {
            return;
          }
          return (window.location.href = this.billingPortalLink);
        }
      });
    },
    billingUrl(userNumber) {
      if (userNumber >= 90000001 && userNumber <= 98999999) {
        this.billingPortalLink = `${this.billingPortalUrl}/Portal1/Login.cms?ReturnUrl=%2fPortal1%2f`;
        return this.billingPortalLink;
      }
      if (userNumber >= 31000000 && userNumber <= 31369999) {
        this.billingPortalLink = `${this.billingPortalUrl}/Portal2/Login.cms?ReturnUrl=%2fPortal2%2f`;
        return this.billingPortalLink;
      }
      if (userNumber >= 99000000 && userNumber <= 99999999) {
        this.billingPortalLink = `${this.billingPortalUrl}/Portal3/Login.cms?ReturnUrl=%2fPortal3%2f`;
        return this.billingPortalLink;
      }
      if (userNumber >= 20000000 && userNumber <= 20999999) {
        this.billingPortalLink = `${this.billingPortalUrl}/Portal4/Login.cms?ReturnUrl=%2fPortal4%2f`;
        console.log(this.billingPortalLink);
        return this.billingPortalLink;
      }
      if (userNumber >= 21000000 && userNumber <= 21009999) {
        this.billingPortalLink = `${this.billingPortalUrl}/Portal5/Login.cms?ReturnUrl=%2fPortal5%2f`;
        return this.billingPortalLink;
      }
      if (userNumber >= 21010000 && userNumber <= 21029999) {
        this.billingPortalLink = `${this.billingPortalUrl}/Portal6/Login.cms?ReturnUrl=%2fPortal6%2f`;
        return this.billingPortalLink;
      }
      if (userNumber >= 10000000 && userNumber <= 10699999) {
        this.billingPortalLink = `${this.billingPortalUrl}/Portal7/Login.cms?ReturnUrl=%2fPortal7%2f`;
        return this.billingPortalLink;
      }
      if (userNumber >= 11100000 && userNumber <= 11999999) {
        this.billingPortalLink = `${this.billingPortalUrl}/Portal8/Login.cms?ReturnUrl=%2fPortal8%2f`;
        return this.billingPortalLink;
      }
      if (userNumber >= 18600000 && userNumber <= 18620000) {
        this.billingPortalLink = `${this.billingPortalUrl}/Portal9/Login.cms?ReturnUrl=%2fPortal9%2f`;
        return this.billingPortalLink;
      }
      this.$swal.fire({
        icon: 'error',
        text: 'Unbekannte Kundennummer'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/custom';

form {
  label {
    font-weight: bold;
  }
}

.customer-service-container {
  color: $interface-dark-three;
  font-family: 'Lato';

  .customer-service-header {
    font-size: 24px;
  }
}

a {
  color: $black;
}
</style>
