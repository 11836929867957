import { sendAjaxRequest, requestError } from './ajax.service.js';

const url = '/public/onetimeaction';

class OneTimeActionService {
  create(action, data, authRequired = true) {
    if (!data._action) {
      if (!action) {
        throw requestError('no action provided');
      }
      // param-reassignment is fine here as we're deliberately extending data only if the action param for the OTA is not present.
      // eslint-disable-next-line no-param-reassign
      data._action = action;
    }
    return sendAjaxRequest(url, 'POST', data, authRequired);
  }
}

export default OneTimeActionService;
