<template>
  <router-link
    v-if="target"
    :to="target"
    class="d-flex align-items-center btn"
    :class="(additionalClasses ? additionalClasses : '') + ' ' + btnColorClass"
    @click="$emit('click')">
    <IconComponent
      v-if="icon"
      :name="icon"
      :class="iconClass"
      :icon-color="iconColor" />
    <i v-if="biIcon" :class="biClass ? biIcon + ' ' + biClass : biIcon"></i>
    {{ title }}
    <slot></slot>
  </router-link>
  <button
    v-else
    class="align-items-center btn"
    :class="
      (icon ? icon : '') +
      ' ' +
      btnColorClass +
      ' ' +
      (additionalClasses ? additionalClasses : '')
    "
    @click="$emit('click')">
    <IconComponent
      v-if="icon"
      :name="icon"
      :class="iconClass"
      :icon-color="iconColor" />
    <i v-if="biIcon" :class="biClass ? biIcon + ' ' + biClass : biIcon"></i>
    {{ title }}
    <slot></slot>
  </button>
</template>

<script>
import IconComponent from '@/components/utility/Icon.vue';

export default {
  name: 'CustomButton',
  components: {
    IconComponent
  },
  props: {
    title: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    iconClass: {
      type: String,
      default: ''
    },
    additionalClasses: {
      type: String,
      default: null
    },
    target: {
      type: String,
      default: null
    },
    btnColorClass: {
      type: String,
      default: 'btn-light'
    },
    biIcon: {
      type: String,
      default: null
    },
    biClass: {
      type: String,
      default: null
    },
    iconColor: {
      type: String,
      default: null
    }
  },
  emits: ['click']
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/custom';

.btn-subheader {
  font-size: 14px;
  font-weight: 600;
  margin-left: 0.25rem;
  svg {
    margin-right: 0.5rem;
  }
}
.content-box {
  .btn.btn-light {
    justify-content: center;
    font-weight: 600;
    margin-bottom: 1rem;
    font-family: 'Mulish';
  }
}
</style>
