var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid align-items-center bg-white subheader border-bottom ps-0"},[(_vm.hasRole('PORTAL_USER'))?_c('div',{staticClass:"d-flex flex-grow-1 justify-content-start",attrs:{"id":"subNavigation"}},[(_vm.showOnRoutes(_vm.salesPartnerRoutes))?_c('div',{staticClass:"subheader-salespartner-routes"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'customerOverview' }}},[_c('SubHeaderNavigationButton',{attrs:{"icon":"usersSecond","text":"Kunden","active":_vm.currentRouteName === 'customerOverview' ||
            _vm.currentRouteName === 'customerForm'}})],1),_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'draftOverview' }}},[_c('SubHeaderNavigationButton',{attrs:{"icon":"deskphone","text":"Entwürfe","active":_vm.currentRouteName === 'draftOverview' ||
            _vm.currentRouteName === 'draftForm'}})],1),_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'orderOverview' }}},[_c('SubHeaderNavigationButton',{attrs:{"icon":"deskphone","text":"Bestellungen","active":_vm.currentRouteName === 'orderOverview' ||
            _vm.currentRouteName === 'orderForm'}})],1),_c('router-link',{staticClass:"nav-link",attrs:{"to":"/#"}},[_c('SubHeaderNavigationButton',{attrs:{"icon":"deskphone","text":"Dokumente"}})],1)],1):_vm._e(),(_vm.showOnRoutes(_vm.wholeSaleRoutes))?_c('div',{staticClass:"subheader-salespartner-routes"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'wholeSaleSearch' }}},[_c('SubHeaderNavigationButton',{attrs:{"icon":"search","text":"Suche","active":_vm.currentRouteName === 'wholeSaleSearch'}})],1),_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'wholeSaleCustomerOverview' }}},[_c('SubHeaderNavigationButton',{attrs:{"icon":"usersSecond","text":"Großhandelskunden","active":_vm.currentRouteName === 'wholeSaleCustomerOverview' ||
            _vm.currentRouteName === 'wholeSaleCustomerServices' ||
            _vm.currentRouteName === 'wholeSaleSipOverview' ||
            _vm.currentRouteName === 'wholeSaleSipCreateNumberForm' ||
            _vm.currentRouteName === 'wholeSalePortNumberForm' ||
            _vm.currentRouteName === 'wholeSaleCustomerAdd' ||
            _vm.currentRouteName === 'wholeSaleBackupRouting' ||
            _vm.currentRouteName === 'wholeSaleCustomerServiceAdd'}})],1),_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'wholeSaleCustomerProcesses' }}},[_c('SubHeaderNavigationButton',{attrs:{"icon":"deskphone","text":"Prozesse","active":_vm.currentRouteName === 'wholeSaleCustomerProcesses'}})],1)],1):_vm._e()]):_c('div',{staticClass:"d-flex flex-grow-1 justify-content-start",attrs:{"id":"subNavigation"}}),(_vm.currentUser)?_c('div',{staticClass:"align-items-center d-flex my-2 justify-content-start"},[_c('CustomButton',{attrs:{"title":_vm.$t('subheader.help'),"icon":"search","icon-color":"black","icon-class":"icon-size-18","additional-classes":"btn-subheader","target":"/help"}})],1):_vm._e(),_c('div',{staticClass:"align-items-center d-flex my-2"},[_c('a',{staticStyle:{"white-space":"nowrap"},attrs:{"href":_vm.url_imprit,"target":"_blank"}},[_c('CustomButton',{attrs:{"title":_vm.$t('subheader.imprint'),"icon":"info","icon-color":"black","icon-class":"icon-size-18","additional-classes":"btn-subheader"}})],1)]),_c('div',{staticClass:"align-items-center d-flex my-2"},[_c('a',{staticStyle:{"white-space":"nowrap"},attrs:{"href":_vm.url_privacyPolicy,"target":"_blank"}},[_c('CustomButton',{attrs:{"title":_vm.$t('subheader.privacyPolicy'),"icon":"lock","icon-color":"black","icon-class":"icon-size-18","additional-classes":"btn-subheader"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }