import ContactService from '../services/contact.service.js';

const initState = { contacts: null, selectedContact: null, company: null };

export default {
  namespaced: true,
  state: initState,
  actions: {
    getContacts({ commit, dispatch }, payload) {
      return ContactService.getContacts(payload).then(
        (contacts) => {
          commit('SET_CONTACTS', contacts);
          return Promise.resolve(contacts);
        },
        (error) => {
          dispatch('reset');
          return Promise.resolve(error);
        }
      );
    },
    setSelectedContact({ commit, getters }, contactId) {
      if (contactId) {
        const contact = getters.getContact(contactId);
        commit('SET_SELECTED_CONTACT', contact);
      } else {
        commit('SET_SELECTED_CONTACT', null);
      }
    },
    setContactCompany({ commit, getters }) {
      const companyData = getters.company;
      commit('SET_CONTACT_COMPANY', companyData);
    },
    reset({ commit }) {
      commit('RESET_CONTACT');
    }
  },
  mutations: {
    SET_CONTACTS(state, contacts) {
      state.contacts = contacts;
    },
    SET_SELECTED_CONTACT(state, selectedContact) {
      state.selectedContact = selectedContact;
    },
    SET_CONTACT_COMPANY(state, company) {
      state.company = company;
    },
    RESET_CONTACT(state) {
      Object.assign(state, initState);
    }
  },
  getters: {
    getContact: (state) => (contactId) => {
      const { contacts } = state;
      for (let i = 0; i < contacts.length; i++) {
        if (contactId === contacts[i].values?.id) {
          return contacts[i];
        }
      }
      return null;
    },
    company: (state) => state.contacts?.[0]
  }
};
