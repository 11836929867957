import EventBus from '@/helper/eventBus.js';

export const loader = {
  showLoader: () => {
    EventBus.$emit('showMainLoader', true);
  },
  hideLoader: () => {
    EventBus.$emit('showMainLoader', false);
  }
};
