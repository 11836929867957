import $ from 'jquery';
import { requestError } from '@/services/ajax.service.js';
import { apiUrl } from '@/const.js';

let store;
export function init(globalStore) {
  store = globalStore;
}

function createFileObjects(simpleFileData) {
  const res = [];
  if (simpleFileData) {
    if (simpleFileData && simpleFileData.length != 0) {
      for (let i = 0; i < simpleFileData.length; i++) {
        const filename = simpleFileData[i].name
          .replace(/Ä/g, 'Ae')
          .replace(/ä/g, 'ae')
          .replace(/Ö/g, 'Oe')
          .replace(/ö/g, 'oe')
          .replace(/Ü/g, 'Ue')
          .replace(/ü/g, 'ue')
          .replace(/ß/g, 'ss');
        res.push({
          name: simpleFileData[i].name,
          filename,
          file: simpleFileData[i],
          index: i
        });
      }
    }
  }
  return res;
}

class FileUploadService {
  uploadFile(file) {
    return this.uploadFiles([file]);
  }

  /**
   * pushes a set of files to the file queue
   * @param {*} files
   * @param {*} foundationId
   * @returns a promise for the executed API call
   */
  uploadFiles(files) {
    const foundationId = store.state.foundation.selectedFoundation.trid;
    const data = createFileObjects(files);
    if (data && Array.isArray(data) && foundationId) {
      const deferred = new $.Deferred();
      const formData = new FormData();

      for (let i = 0; i < data.length; i++) {
        const file = data[i];
        formData.append(`file${i}`, file.file);
        formData.append(`name${i}`, file.filename);
      }

      const request = new XMLHttpRequest();
      formData.append('_jwt_token', store.state.auth.user.token);
      request.open('POST', `${apiUrl}/../upload/files/${foundationId}`);
      request.onload = () => {
        const res = request.responseText;
        let obj;
        if (res != null) {
          obj = JSON.parse(res);
        }
        if (request.status == 200) {
          deferred.resolve(obj);
        } else {
          deferred.reject(requestError(res));
        }
      };
      request.send(formData);

      return deferred.promise();
    }
    throw new requestError({
      _errorMessage: 'Es wurden keine Dateien ausgewählt'
    });
  }
}

export default FileUploadService;
