import { sendAjaxRequest, requestError } from '@/services/ajax.service.js';

let store;
export function init(globalStore) {
  store = globalStore;
}

class WholeSaleService {
  search(data = {}) {
    const foundationId = store.state.foundation.selectedFoundation.trid;

    return sendAjaxRequest(
      `/foundation/${foundationId}/v3/wholesale`,
      'POST',
      data
    ).then(
      (res) => {
        if (res._error) {
          throw requestError(res);
        } else {
          return res;
        }
      },
      (error) => error
    );
  }

  getSip(customerId, serviceId, sipId) {
    const foundationId = store.state.foundation.selectedFoundation.trid;

    return sendAjaxRequest(
      `/foundation/${foundationId}/v3/wholesale/customer/${customerId}/service/${serviceId}/sip/${sipId}`,
      'GET'
    ).then(
      (res) => {
        if (res._error) {
          throw requestError(res);
        } else {
          return res;
        }
      },
      (error) => error
    );
  }

  getWholeSaleCustomerList(
    searchString = '',
    selectedWholeSaleCustomerId = ''
  ) {
    const foundationId = store.state.foundation.selectedFoundation.trid;
    const data = {
      search: searchString,
      _selected: selectedWholeSaleCustomerId
    };
    return sendAjaxRequest(
      `/foundation/${foundationId}/v3/wholesale/customer`,
      'GET',
      data
    ).then(
      (res) => {
        if (res._error) {
          throw requestError(res);
        } else {
          return res;
        }
      },
      (error) => error
    );
  }

  getWholeSaleCustomerServices(selectedWholeSaleCustomerId, searchString = '') {
    const foundationId = store.state.foundation.selectedFoundation.trid;
    const data = { search: searchString };
    return sendAjaxRequest(
      `/foundation/${foundationId}/v3/wholesale/customer/${selectedWholeSaleCustomerId}/service?_active=false`,
      'GET',
      data
    ).then(
      (res) => {
        if (res._error) {
          throw requestError(res);
        } else {
          return res;
        }
      },
      (error) => error
    );
  }

  getWholeSaleCustomerProcesses(searchString = '') {
    const foundationId = store.state.foundation.selectedFoundation.trid;
    let search = searchString;
    if (search === '') {
      search = '*v3*';
    }

    return sendAjaxRequest(
      `/foundation/${foundationId}/bpm?search=${search}`,
      'GET'
    ).then(
      (res) => {
        if (res._error) {
          throw requestError(res);
        } else {
          return res;
        }
      },
      (error) => error
    );
  }

  getWholeSaleSingleCustomer(customerId) {
    const foundationId = store.state.foundation.selectedFoundation.trid;
    return sendAjaxRequest(
      `/foundation/${foundationId}/v3/wholesale/customer/${customerId}`,
      'GET'
    ).then(
      (res) => {
        if (res._error) {
          throw requestError(res);
        } else {
          return res;
        }
      },
      (error) => error
    );
  }

  getWholeSaleSingleService(customerId, serviceId) {
    const foundationId = store.state.foundation.selectedFoundation.trid;
    return sendAjaxRequest(
      `/foundation/${foundationId}/v3/wholesale/customer/${customerId}/service/${serviceId}`,
      'GET'
    ).then(
      (res) => {
        if (res._error) {
          throw requestError(res);
        } else {
          return res;
        }
      },
      (error) => error
    );
  }

  getWholeSaleSingleProcess(processId) {
    const foundationId = store.state.foundation.selectedFoundation.trid;
    return sendAjaxRequest(
      `/foundation/${foundationId}/bpm/${processId}`,
      'GET'
    ).then(
      (res) => {
        if (res._error) {
          throw requestError(res);
        } else {
          return res;
        }
      },
      (error) => error
    );
  }

  setCustomerExternalId(customerId, data) {
    const foundationId = store.state.foundation.selectedFoundation.trid;
    return sendAjaxRequest(
      `/foundation/${foundationId}/v3/wholesale/customer/${customerId}`,
      'POST',
      data
    ).then(
      (res) => {
        if (res._error) {
          throw requestError(res);
        } else {
          return res;
        }
      },
      (error) => error
    );
  }

  // /delegate/rest/foundation/90136441/v3/wholesale/customer/97015371/service/5763248/sip/410206
  setSipData(customerId, serviceId, sipId, data) {
    const foundationId = store.state.foundation.selectedFoundation.trid;

    return sendAjaxRequest(
      `/foundation/${foundationId}/v3/wholesale/customer/${customerId}/service/${serviceId}/sip/${sipId}`,
      'POST',
      data
    ).then(
      (res) => {
        if (res._error) {
          throw requestError(res);
        } else {
          return res;
        }
      },
      (error) => error
    );
  }

  loadWholesaleType() {
    const foundationId = store.state.foundation.selectedFoundation.trid;

    const data = { _method: 'POST', _action: 'status' };

    return sendAjaxRequest(
      `/foundation/${foundationId}/v3/wholesale`,
      'POST',
      data
    ).then(
      (res) => {
        if (res._error) {
          throw requestError(res);
        } else {
          return res;
        }
      },
      (error) => error
    );
  }

  // /delegate/rest/foundation/90136441/v3/wholesale/customer/97015371/service/5763248/sip
  createSipNumber(customerId, serviceId, data) {
    const foundationId = store.state.foundation.selectedFoundation.trid;

    return sendAjaxRequest(
      `/foundation/${foundationId}/v3/wholesale/customer/${customerId}/service/${serviceId}/sip`,
      'POST',
      data
    ).then(
      (res) => {
        if (res._error) {
          throw requestError(res);
        } else {
          return res;
        }
      },
      (error) => error
    );
  }

  // /delegate/rest/foundation/90136441/v3/wholesale
  getCarrier(number) {
    const foundationId = store.state.foundation.selectedFoundation.trid;

    const data = { _action: 'getCarrier', _method: 'POST', number };

    return sendAjaxRequest(
      `/foundation/${foundationId}/v3/wholesale`,
      'POST',
      data
    ).then(
      (res) => {
        if (res._error) {
          throw requestError(res);
        } else {
          return res;
        }
      },
      (error) => error
    );
  }

  // delegate/rest/foundation/90136441/v3/wholesale/customer/97027894/service/5765654/sip
  portinNumber(customerId, serviceId, data) {
    const foundationId = store.state.foundation.selectedFoundation.trid;

    return sendAjaxRequest(
      `/foundation/${foundationId}/v3/wholesale/customer/${customerId}/service/${serviceId}/sip`,
      'POST',
      data
    ).then(
      (res) => {
        if (res._error) {
          throw requestError(res);
        } else {
          return res;
        }
      },
      (error) => error
    );
  }

  getCustomerSips(customerId) {
    const foundationId = store.state.foundation.selectedFoundation.trid;

    const data = { _action: 'numbers' };

    return sendAjaxRequest(
      `/foundation/${foundationId}/v3/wholesale/customer/${customerId}`,
      'POST',
      data
    ).then(
      (res) => {
        if (res._error) {
          throw requestError(res);
        } else {
          return res;
        }
      },
      (error) => error
    );
  }

  // delegate/rest/foundation/90136441/v3/wholesale/customer/97015371/service/5763248/cf?type=IMMEDIATELY&&_=1646206219753
  getCFList(customerId, serviceId) {
    const foundationId = store.state.foundation.selectedFoundation.trid;

    const data = { type: 'IMMEDIATELY' };

    return sendAjaxRequest(
      `/foundation/${foundationId}/v3/wholesale/customer/${customerId}/service/${serviceId}/cf`,
      'GET',
      data
    ).then(
      (res) => {
        if (res._error) {
          throw requestError(res);
        } else {
          return res;
        }
      },
      (error) => error
    );
  }

  // delegate/rest/foundation/90136441/v3/wholesale/customer/97015371/service/5763248/cf?type=IMMEDIATELY&&_=1646206219753
  getBRList(customerId, serviceId) {
    const foundationId = store.state.foundation.selectedFoundation.trid;

    const data = { type: 'BACKUP_ROUTING' };

    return sendAjaxRequest(
      `/foundation/${foundationId}/v3/wholesale/customer/${customerId}/service/${serviceId}/cf`,
      'GET',
      data
    ).then(
      (res) => {
        if (res._error) {
          throw requestError(res);
        } else {
          return res;
        }
      },
      (error) => error
    );
  }

  // /delegate/rest/foundation/90136441/v3/wholesale/customer/97000052/service/5739365/sip/361512/ngn
  getSipNgn(customerId, serviceId, sipid) {
    const foundationId = store.state.foundation.selectedFoundation.trid;

    return sendAjaxRequest(
      `/foundation/${foundationId}/v3/wholesale/customer/${customerId}/service/${serviceId}/sip/${sipid}/ngn`,
      'GET'
    ).then(
      (res) => {
        if (res._error) {
          throw requestError(res);
        } else {
          return res;
        }
      },
      (error) => error
    );
  }

  // delegate/rest/foundation/90136441/v3/wholesale/customer/97000052/service/5739365/cf
  sendSingleCfBatch(customerId, serviceId, data) {
    const foundationId = store.state.foundation.selectedFoundation.trid;

    return sendAjaxRequest(
      `/foundation/${foundationId}/v3/wholesale/customer/${customerId}/service/${serviceId}/cf`,
      'POST',
      data
    ).then(
      (res) => {
        if (res._error) {
          throw requestError(res);
        } else {
          return res;
        }
      },
      (error) => error
    );
  }

  // delegate/rest/foundation/90136441/v3/wholesale/customer/97000052/service/5739365/cf
  sendSingleBrBatch(customerId, serviceId, data) {
    const foundationId = store.state.foundation.selectedFoundation.trid;

    data.type = 'BACKUP_ROUTING';

    return sendAjaxRequest(
      `/foundation/${foundationId}/v3/wholesale/customer/${customerId}/service/${serviceId}/cf`,
      'POST',
      data
    ).then(
      (res) => {
        if (res._error) {
          throw requestError(res);
        } else {
          return res;
        }
      },
      (error) => error
    );
  }
}

export default new WholeSaleService();
