<!-- eslint-disable vue/multi-word-component-names | This component is special as it uses Icons created by vue-svg-loader-->
<template>
  <component
    :is="iconComponent"
    role="img"
    class="inline-block"
    :class="iconColor ? iconColor : ''" />
</template>

<script>
import SearchIcon from '@/assets/images/svg/search.svg';
import UsercheckIcon from '@/assets/images/svg/user-check.svg';
import LockIcon from '@/assets/images/svg/lock.svg';
import InfoIcon from '@/assets/images/svg/info.svg';
import ToolIcon from '@/assets/images/svg/tool.svg';
import AccountIcon from '@/assets/images/svg/account.svg';
import UsersIcon from '@/assets/images/svg/users.svg';
import DocumentIcon from '@/assets/images/svg/document.svg';
import FolderIcon from '@/assets/images/svg/folder.svg';
import FolderThinIcon from '@/assets/images/svg/folder-thin.svg';
import VideocameraIcon from '@/assets/images/svg/videocamera.svg';
import UseraddIcon from '@/assets/images/svg/user-plus.svg';
import UploadIcon from '@/assets/images/svg/upload.svg';
import FileIcon from '@/assets/images/svg/file.svg';
import DeskphoneIcon from '@/assets/images/svg/deskphone.svg';
import UsersSecondIcon from '@/assets/images/svg/users-second.svg';
import UserIcon from '@/assets/images/svg/user.svg';
import LogoGammaWhiteIcon from '@/assets/images/svg/logo_gamma_white.svg';
import LogoEtcWhiteIcon from '@/assets/images/svg/logo_etc_white.svg';
import EyeIcon from '@/assets/images/svg/eye.svg';
import QuestionMarkRoundIcon from '@/assets/images/svg/question-mark-round.svg';
import MagicIcon from '@/assets/images/svg/magic.svg';
import SaveIcon from '@/assets/images/svg/save.svg';
import CheckmarkRoundIcon from '@/assets/images/svg/checkmark-round.svg';
import CrossRoundIcon from '@/assets/images/svg/cross-round.svg';
import ExclamationRoundIcon from '@/assets/images/svg/exclamation-round.svg';
import PlusIcon from '@/assets/images/svg/plus.svg';
import PlusPurpleIcon from '@/assets/images/svg/plus_purple.svg';
import CrossRoundWhiteIcon from '@/assets/images/svg/cross-round-white18px.svg';
import HomeIcon from '@/assets/images/svg/home.svg';
import EditIcon from '@/assets/images/svg/edit.svg';
import CrossIcon from '@/assets/images/svg/cross.svg';
import TrashIcon from '@/assets/images/svg/trash.svg';
import StarIcon from '@/assets/images/svg/star.svg';
import DownloadIcon from '@/assets/images/svg/download.svg';
import CheckIcon from '@/assets/images/svg/check.svg';
import LinkIcon from '@/assets/images/svg/link.svg';
import ServiceIcon from '@/assets/images/svg/service.svg';
import CallIcon from '@/assets/images/svg/call.svg';
import StickyNoteIcon from '@/assets/images/svg/sticky_note.svg';
import PathIcon from '@/assets/images/svg/path.svg';
import BackIcon from '@/assets/images/svg/back.svg';
import LoginIcon from '@/assets/images/svg/login.svg';

// register all icons in array for validation and backwards compatibility
// forgoing to need to refactor
const icons = {
  search: SearchIcon,
  usercheck: UsercheckIcon,
  lock: LockIcon,
  info: InfoIcon,
  tool: ToolIcon,
  account: AccountIcon,
  users: UsersIcon,
  document: DocumentIcon,
  folder: FolderIcon,
  fodlerThin: FolderThinIcon,
  videocamera: VideocameraIcon,
  useradd: UseraddIcon,
  upload: UploadIcon,
  file: FileIcon,
  deskphone: DeskphoneIcon,
  usersSecond: UsersSecondIcon,
  user: UserIcon,
  logoGammaWhite: LogoGammaWhiteIcon,
  logoEtcWhite: LogoEtcWhiteIcon,
  eye: EyeIcon,
  questionMarkRound: QuestionMarkRoundIcon,
  magic: MagicIcon,
  save: SaveIcon,
  checkmarkRound: CheckmarkRoundIcon,
  crossRound: CrossRoundIcon,
  exclamationRound: ExclamationRoundIcon,
  plus: PlusIcon,
  plus_purple: PlusPurpleIcon,
  crossRoundWhite: CrossRoundWhiteIcon,
  home: HomeIcon,
  edit: EditIcon,
  cross: CrossIcon,
  trash: TrashIcon,
  star: StarIcon,
  download: DownloadIcon,
  check: CheckIcon,
  link: LinkIcon,
  service: ServiceIcon,
  call: CallIcon,
  stickyNote: StickyNoteIcon,
  path: PathIcon,
  back: BackIcon,
  login: LoginIcon
};

export default {
  props: {
    name: {
      type: String,
      required: true,
      validator(value) {
        const validIcon = value in icons;
        if (!validIcon) console.error(`Could not resolve icon ${value}!`);
        return validIcon;
      }
    },
    iconColor: {
      type: String,
      default: null
    }
  },

  computed: {
    iconComponent() {
      return icons[this.name];
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables';

.black {
  fill: $black;
}

.white {
  fill: $white;
}

.primary {
  fill: $primary;
}

.secondary {
  fill: $secondary;
}

.light {
  fill: $light;
}

.lightgreen {
  fill: #a5dc86;
}

.lightred {
  fill: $red;
}

.orange {
  fill: #f8bb86;
}
</style>
