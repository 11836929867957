import $ from 'jquery';
import EventBus from '@/helper/eventBus.js';
import { apiUrl } from '@/const.js';

let store;
export function init(globalStore) {
  store = globalStore;
}

export function sendAjaxRequest(
  endpoint,
  method,
  data = {},
  addRequestHeader = true,
  processData = true,
  dataType = 'json',
  cache = false,
  crossDomain = true
) {
  if (localStorage && console && localStorage.getItem('debugAjax')) {
    console.debug(`>>> ${method} ${endpoint}\n    ${JSON.stringify(data)}`);
  }

  return $.ajax({
    url: apiUrl + endpoint,
    dataType,
    crossDomain,
    method,
    cache,
    data,
    processData,
    beforeSend(xhr) {
      if (addRequestHeader) {
        xhr.setRequestHeader(
          'Authorization',
          `Bearer ${store?.state.auth.user.token}`
        );
      }
    }
  })
    .done((result) => {
      if (localStorage && console && localStorage.getItem('debugAjax')) {
        console.debug(`<<< OK ${JSON.stringify(result)}`);
      }
      // error handling, because actual http error codes cannot be used due to how
      // http error codes are handled in the backend
      if (result._error) {
        handleError(result._error, result._errorMessage);
      }
      return result;
    })
    .fail((result) => {
      if (localStorage && console && localStorage.getItem('debugAjax')) {
        console.debug(`<<< ERROR\n${JSON.stringify(result)}`);
      }
      handleError(result.statusMessage, result.status);
      return result;
    });
}

/**
 * Because of error codes being present in successful reequests due to how vue works
 * within the backend, error handling needs to be done in both on promise done and fail
 * this is just logic for it, needs the specific fields containing error and message
 * @param {number} errorCode
 * @param {string} errorText
 */
function handleError(errorCode, errorText) {
  // $emit specific error responses to frontend for user action (900: Account suspended, 901: Reset Pwd!, 902: Accept ToS)
  const errorCodes = [900, 901, 902];
  if (errorCode == 902) {
    store.dispatch('auth/invalidateToS');
  }
  if (errorCodes.includes(errorCode)) {
    EventBus.$emit('showDialog', {
      title: errorText,
      statusCode: errorCode
    });
  }
}

export function requestError(response) {
  const error = new Error(response._errorMessage);
  error.status = response._error;
  return error;
}
