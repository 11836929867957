import Vue from 'vue';
import { tokenTimespan } from '@/const.js';
import { sendAjaxRequest, requestError } from './ajax.service.js';

/**
 * Utitlity function used to decode the JWT
 * @param {token} token
 * @returns json with the payload of the response
 */
function parseJwt(token) {
  const base64Payload = token.split('.')[1];
  window.Buffer = window.Buffer || require('buffer').Buffer;
  const payload = Buffer.from(base64Payload, 'base64');
  return JSON.parse(payload.toString());
}

function tokenExpiredToast() {
  Vue.prototype.$toastr.e(
    'Abgemeldet',
    'Ihre Sitzung ist abgelaufen. Bitte loggen Sie sich neu ein.'
  );
}

function createUserObject(tokenResult) {
  if (tokenResult._error) {
    console.error('token expired: ', tokenResult._error);
    tokenExpiredToast();
    throw requestError(tokenResult);
  } else {
    const tokenContent = parseJwt(tokenResult.token);
    const user = { ...tokenResult, _timestamp: tokenContent.exp * 1000 };
    if (!user.roles) {
      if (process.env.VUE_APP_DEFAULT_ROLES) {
        user.roles = process.env.VUE_APP_DEFAULT_ROLES.split(',');
      }
    }
    return user;
  }
}

class AuthService {
  // reference timer to cancel remaining refresh timeout on logout
  refreshTokenTimer = null;

  login(user) {
    return sendAjaxRequest(
      '/jwt_token',
      'POST',
      {
        _ticket: `acc:${user.username}:${user.password}`,
        timeout: tokenTimespan // optional timeout parameter for testing - 1h max. | 1h default
      },
      false
    ).then(
      (res) => createUserObject(res),
      (error) => {
        console.log('login error: ', error);
        return error;
      }
    );
  }

  refreshToken() {
    return sendAjaxRequest('/jwt_token', 'POST', {
      timeout: tokenTimespan // optional timeout parameter for testing - 1h max. | 1h default
    }).then(
      (res) => createUserObject(res),
      (error) => {
        console.error(error);
        tokenExpiredToast();
        throw requestError(error);
      }
    );
  }
}

export default new AuthService();
