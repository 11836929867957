<template>
  <div
    class="d-flex align-items-center px-4 py-4 subnav-button h-100"
    :class="active === true ? 'active' : ''">
    <IconComponent
      v-if="icon !== null"
      :name="icon"
      icon-color="secondary"
      class="icon-size" />
    <span class="button-label ps-3">{{ text }}</span>
  </div>
</template>

<script>
import IconComponent from '@/components/utility/Icon.vue';

export default {
  name: 'SubHeaderNavigationButton',
  components: {
    IconComponent
  },
  props: {
    text: {
      type: String,
      default: null,
      required: true
    },
    icon: {
      type: String,
      default: null
    },
    active: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables';

.button-label {
  font-family: 'Mulish';
  font-size: 14px;
  font-weight: 800;
}

.subNavigation {
  .subnav-button {
    &.active {
      border-bottom: 2px solid $illustrations-blue;

      &:hover {
        border-bottom: 2px solid $secondary;
      }
    }

    &:hover {
      background-color: $secondary;
      color: $white;

      > svg {
        fill: $white;
      }
    }
  }

  .icon-size {
    width: 25px;
    height: auto;
  }
}
</style>
