import CustomerService from '../services/customer.service.js';

const initState = { customers: null, selectedCustomer: null };

export default {
  namespaced: true,
  state: initState,
  actions: {
    getCustomers({ commit, dispatch }, payload = {}) {
      const { searchString } = payload;
      const selectedCutomerId = payload.selectedCustomerId;

      return CustomerService.getCustomerList(
        searchString,
        selectedCutomerId
      ).then(
        (customers) => {
          commit('SET_CUSTOMERS', customers);
          return Promise.resolve(customers);
        },
        (error) => {
          dispatch('reset');
          return Promise.resolve(error);
        }
      );
    },
    setSelectedCustomer({ commit, state }, customerId) {
      const selectedCustomer = state.customers?.find(
        (customer) => customer.id === customerId
      );
      commit('SET_SELECTED_CUSTOMER', selectedCustomer);
    },
    reset({ commit }) {
      commit('RESET_CUSTOMER');
    }
  },
  mutations: {
    SET_CUSTOMERS(state, customers) {
      state.customers = customers;
    },
    SET_SELECTED_CUSTOMER(state, selectedCustomer) {
      state.selectedCustomer = selectedCustomer;
    },
    RESET_CUSTOMER(state) {
      Object.assign(state, initState);
    }
  }
};
