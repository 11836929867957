import Vue from 'vue';
import VueMeta from 'vue-meta';
import Router from 'vue-router';
import Login from '@/views/Login.vue';
import store from '@/store/index.js';
import '@/assets/scss/variables.scss';

Vue.use(Router);
Vue.use(VueMeta);

export const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'login',
      component: Login,
      beforeEnter: (to, from, next) => {
        if (store.state.auth.status.loggedIn) {
          next('/my-portal');
        } else {
          next();
        }
      }
    },
    {
      path: '/search',
      name: 'search',
      // lazy-loaded
      component: () => import(/* webpackPrefetch: true */ '../views/Search.vue')
    },
    {
      path: '/my-portal',
      name: 'myPortal',
      // lazy-loaded
      component: () =>
        import(/* webpackPrefetch: true */ '../views/MyPortal.vue')
    },
    {
      path: '/terms-of-use',
      name: 'termsOfUse',
      // lazy-loaded
      component: () =>
        import(/* webpackPrefetch: true */ '../views/TermsOfUse.vue')
    },
    {
      path: '/reset-password',
      name: 'resetPassword',
      // lazy-loaded
      component: () =>
        import(/* webpackPrefetch: true */ '../views/ResetPassword.vue')
    },
    {
      path: '/customer/profile',
      name: 'customerProfile',
      // lazy-loaded
      component: () =>
        import(/* webpackPrefetch: true */ '../views/CustomerProfile.vue'),
      beforeEnter(to, from, next) {
        if (store.getters['auth/hasRole'](['SP']) || store.getters['auth/hasRole'](['WS'])) {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      path: '/sales-partners/customers',
      name: 'customerOverview',
      // lazy-loaded
      component: () =>
        import(/* webpackPrefetch: true */ '../views/CustomerOverview.vue'),
      beforeEnter(to, from, next) {
        if (store.getters['auth/hasRole'](['SP'])) {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      path: '/sales-partners/drafts',
      name: 'draftOverview',
      // lazy-loaded
      component: () =>
        import(/* webpackPrefetch: true */ '../views/DraftOverview.vue'),
      beforeEnter(to, from, next) {
        if (store.getters['auth/hasRole'](['SP'])) {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      path: '/sales-partners/drafts/edit',
      name: 'draftForm',
      // lazy-loaded
      component: () =>
        import(/* webpackPrefetch: true */ '../views/DraftAndOrderForm.vue'),
      beforeEnter(to, from, next) {
        if (store.getters['auth/hasRole'](['SP'])) {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      path: '/sales-partners/orders',
      name: 'orderOverview',
      // lazy-loaded
      component: () =>
        import(/* webpackPrefetch: true */ '../views/OrderOverview.vue'),
      beforeEnter(to, from, next) {
        if (store.getters['auth/hasRole'](['SP'])) {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      path: '/sales-partners/orders/edit',
      name: 'orderForm',
      // lazy-loaded
      component: () =>
        import(/* webpackPrefetch: true */ '../views/DraftAndOrderForm.vue'),
      beforeEnter(to, from, next) {
        if (store.getters['auth/hasRole'](['SP'])) {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      path: '/sales-partners/order-submit-wizard',
      name: 'orderSubmitWizard',
      // lazy-loaded
      component: () =>
        import(/* webpackPrefetch: true */ '../views/OrderSubmitWizard.vue'),
      beforeEnter(to, from, next) {
        if (store.getters['auth/hasRole'](['SP'])) {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      path: '/sales-partners/customers/edit',
      name: 'customerForm',
      // lazy-loaded
      component: () =>
        import(/* webpackPrefetch: true */ '../views/CustomerForm.vue'),
      beforeEnter(to, from, next) {
        if (store.getters['auth/hasRole'](['SP'])) {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      path: '/documents',
      name: 'documents',
      // lazy-loaded
      component: () =>
        import(/* webpackPrefetch: true */ '../views/Documents.vue'),
      beforeEnter(to, from, next) {
        if (store.getters['auth/hasRole'](['SP'])) {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      path: '/tutorials',
      name: 'tutorials',
      // lazy-loaded
      component: () =>
        import(/* webpackPrefetch: true */ '../views/Tutorials.vue'),
      beforeEnter(to, from, next) {
        if (store.getters['auth/hasRole'](['SP'])) {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      path: '/TestView',
      name: 'FileBrowserBoxView',
      // lazy-loaded
      component: () =>
        import(
          /* webpackPrefetch: true */ '../views/ComponentTestViews/FileBrowserBoxView.vue'
        )
    },
    /**
     * Wholesaler routes
     */
    {
      path: '/wholesale/search',
      name: 'wholeSaleSearch',
      // lazy-loaded
      component: () =>
        import(/* webpackPrefetch: true */ '../views/gnc/WholeSaleSearch.vue'),
      beforeEnter(to, from, next) {
        if (store.getters['auth/hasRole'](['WS'])) {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      path: '/wholesale/customer-coredata',
      name: 'customerCoredataOverview',
      // lazy-loaded
      component: () =>
        import(
          /* webpackPrefetch: true */ '../views/gnc/CustomerCoredataOverview.vue'
        ),
      beforeEnter(to, from, next) {
        if (store.getters['auth/hasRole'](['WS'])) {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      path: '/wholesale/sip',
      name: 'wholeSaleSipOverview',
      // lazy-loaded
      component: () =>
        import(
          /* webpackPrefetch: true */ '../views/gnc/WholeSaleSipOverview.vue'
        ),
      beforeEnter(to, from, next) {
        if (store.getters['auth/hasRole'](['WS'])) {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      path: '/wholesale/backuprouting',
      name: 'wholeSaleBackupRouting',
      // lazy-loaded
      component: () =>
        import(
          /* webpackPrefetch: true */ '../views/gnc/WholeSaleBackupRouting.vue'
        ),
      beforeEnter(to, from, next) {
        if (store.getters['auth/hasRole'](['WS'])) {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      path: '/wholesale/sip-add-number',
      name: 'wholeSaleSipCreateNumberForm',
      // lazy-loaded
      component: () =>
        import(
          /* webpackPrefetch: true */ '../views/gnc/WholeSaleSipCreateNumberForm.vue'
        ),
      beforeEnter(to, from, next) {
        if (store.getters['auth/hasRole'](['WS'])) {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      path: '/wholesale/sip-port-number',
      name: 'wholeSalePortNumberForm',
      // lazy-loaded
      component: () =>
        import(
          /* webpackPrefetch: true */ '../views/gnc/WholeSalePortNumberForm.vue'
        ),
      beforeEnter(to, from, next) {
        if (store.getters['auth/hasRole'](['WS'])) {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      path: '/wholesale/customers',
      name: 'wholeSaleCustomerOverview',
      // lazy-loaded
      component: () =>
        import(
          /* webpackPrefetch: true */ '../views/gnc/WholeSaleCustomerOverview.vue'
        ),
      beforeEnter(to, from, next) {
        if (store.getters['auth/hasRole'](['WS'])) {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      path: '/wholesale/customer/add',
      name: 'wholeSaleCustomerAdd',
      // lazy-loaded
      component: () =>
        import(
          /* webpackPrefetch: true */ '../views/gnc/WholeSaleCustomerAdd.vue'
        ),
      beforeEnter(to, from, next) {
        if (store.getters['auth/hasRole'](['WS'])) {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      path: '/wholesale/customer/services',
      name: 'wholeSaleCustomerServices',
      // lazy-loaded
      component: () =>
        import(
          /* webpackPrefetch: true */ '../views/gnc/WholeSaleCustomerServices.vue'
        ),
      beforeEnter(to, from, next) {
        if (store.getters['auth/hasRole'](['WS'])) {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      path: '/wholesale/customer/processes',
      name: 'wholeSaleCustomerProcesses',
      // lazy-loaded
      component: () =>
        import(
          /* webpackPrefetch: true */ '../views/gnc/WholeSaleCustomerProcesses.vue'
        ),
      beforeEnter(to, from, next) {
        if (store.getters['auth/hasRole'](['WS'])) {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      path: '/wholesale/customer/service/add',
      name: 'wholeSaleCustomerServiceAdd',
      // lazy-loaded
      component: () =>
        import(
          /* webpackPrefetch: true */ '../views/gnc/WholeSaleCustomerServiceAdd.vue'
        ),
      beforeEnter(to, from, next) {
        if (store.getters['auth/hasRole'](['WS'])) {
          next();
        } else {
          next('/');
        }
      }
    },
    /**
     * ETC routes
     */
    {
      path: '/etc/tickets',
      name: 'EtcTicketOverview',
      component: () =>
        import(/* webpackPrefetch: true */ '../views/etc/TicketOverview.vue')
    },
    {
      path: '/etc/tickets/edit',
      name: 'EtcTicketForm',
      component: () =>
        import(/* webpackPrefetch: true */ '../views/etc/TicketForm.vue')
    },
    {
      path: '/etc/documents/distribution-services',
      name: 'EtcDocumentsDistributionServices',
      component: () =>
        import(
          /* webpackPrefetch: true */ '../views/etc/EtcDocumentsDistributionServices.vue'
        ),
      beforeEnter(to, from, next) {
        if (
          store.getters['auth/hasRole'](['PORTAL_ETC_PORTAL_ADMIN', 'PORTAL_EMPLOYEE'])
        ) {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      path: '/etc/documents/commission-lists',
      name: 'EtcDocumentsComissionLists',
      component: () =>
        import(
          /* webpackPrefetch: true */ '../views/etc/EtcDocumentsComissionLists.vue'
        ),
      beforeEnter(to, from, next) {
        if (
          store.getters['auth/hasRole']([
            'PORTAL_ETC_COMISSION_PAPERS',
            'PORTAL_EMPLOYEE'
          ])
        ) {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      path: '/etc/documents/contracts',
      name: 'EtcDocumentsContracts',
      component: () =>
        import(
          /* webpackPrefetch: true */ '../views/etc/EtcDocumentsContracts.vue'
        ),
      beforeEnter(to, from, next) {
        if (
          store.getters['auth/hasRole'](['PORTAL_ETC_PORTAL_ADMIN', 'PORTAL_EMPLOYEE'])
        ) {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      path: '/etc/documents/billing-documents',
      name: 'EtcDocumentsBillingDocuments',
      component: () =>
        import(
          /* webpackPrefetch: true */ '../views/etc/EtcDocumentsBillingDocuments.vue'
        ),
      beforeEnter(to, from, next) {
        if (
          store.getters['auth/hasRole']([
            'PORTAL_ETC_COMISSION_STATEMENT',
            'PORTAL_EMPLOYEE'
          ])
        ) {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      path: '/etc/documents/network-operators',
      name: 'EtcDocumentsNetworkOperators',
      component: () =>
        import(
          /* webpackPrefetch: true */ '../views/etc/EtcDocumentsNetworkOperators.vue'
        )
    },
    {
      path: '/etc/profile',
      name: 'EtcProfileOverview',
      component: () =>
        import(/* webpackPrefetch: true */ '../views/etc/ProfileOverview.vue'),
      beforeEnter(to, from, next) {
        if (
          store.getters['auth/hasRole'](['PORTAL_ETC_PORTAL_ADMIN', 'PORTAL_EMPLOYEE'])
        ) {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      path: '/etc/profile/contactform',
      name: 'EtcContactForm',
      component: () =>
        import(/* webpackPrefetch: true */ '../views/etc/ContactForm.vue')
    },
    {
      path: '/etc/profile/companyform',
      name: 'EtcCompanyForm',
      component: () =>
        import(/* webpackPrefetch: true */ '../views/etc/EtcCompanyForm.vue')
    },
    {
      path: '/etc/academy',
      name: 'EtcAcademyOverview',
      component: () =>
        import(/* webpackPrefetch: true */ '../views/etc/AcademyOverview.vue')
    },
    {
      path: '/etc/network-operators',
      name: 'EtcNetworkOperatorOverview',
      component: () =>
        import(
          /* webpackPrefetch: true */ '../views/etc/NetworkOperatorOverview.vue'
        )
    }
  ]
});

// TODO switch logic to component requests?!
router.beforeEach(async (to, from, next) => {
  const publicPages = ['/', '/reset-password', '/favicon.ico', '/terms-of-use'];
  const isAuthRequired = !publicPages.includes(to.path);
  const userExists = store.state.auth.user != null;
  const timestampExists = store.state.auth.user?._timestamp != null;
  const isTokenExpired =
    userExists &&
    timestampExists &&
    store.state.auth.user?._timestamp < Date.now();

  if (isAuthRequired) {
    const q = to.query;
    delete q.redirect;
    const destination = {
      name: 'login',
      query: { redirect: to.path, ...q },
      hash: to.hash
    };
    if (!store.state.auth.user) {
      // needs to be logged in, but no user logged in => show login
      next(destination);
      return;
    }
    if (isAuthRequired && isTokenExpired) {
      // logged in but token expired => logout & show login page
      store.dispatch('auth/logout').then(
        () => {
          next(destination);
        },
        (reason) => {
          next(destination);
          console.log('Reason: ', reason);
        }
      );
      return;
    }
  }
  next();
});
