import { sendAjaxRequest, requestError } from '@/services/ajax.service.js';

let store;
export function init(globalStore) {
  store = globalStore;
}
class CustomerService {
  getCustomerList(searchString = '', selectedCutomerId = '') {
    const foundationId = store.state.foundation.selectedFoundation.trid;
    const data = {
      search: searchString,
      _selected: selectedCutomerId,
      _sync: true,
      type: 'hfo.vp.customer'
    };
    return sendAjaxRequest(
      `/foundation/${foundationId}/data`,
      'GET',
      data
    ).then(
      (res) => {
        if (res._error) {
          throw requestError(res);
        } else {
          return res;
        }
      },
      (error) => error
    );
  }
}

export default new CustomerService();
