import { sendAjaxRequest, requestError } from '@/services/ajax.service.js';

let store;
export function init(globalStore) {
  store = globalStore;
}
class ContactService {
  getContacts(payload) {
    const data = { search: '' };
    const foundationId = store.state.foundation.selectedFoundation.trid;
    if (payload.searchString !== '') {
      if (payload.searchString) {
        data.search += payload.searchString;
      }
    }
    return sendAjaxRequest(
      `/foundation/${foundationId}/contact/`,
      'GET',
      data
    ).then(
      (res) => {
        if (res._error) {
          throw requestError(res);
        } else {
          // filter
          if (payload.searchString !== '') {
            res = res.filter(
              (entry) =>
                entry.companyname1.includes(payload.searchString) ||
                entry.companyname2.includes(payload.searchString) ||
                entry.name.includes(payload.searchString) ||
                entry.values.buha_email.includes(payload.searchString) ||
                entry.values.bus1_email.includes(payload.searchString) ||
                entry.values.ctc_firstName.includes(payload.searchString) ||
                entry.values.head_email.includes(payload.searchString)
            );
          }
          return res;
        }
      },
      (error) => error
    );
  }

  getContactData(userName) {
    const foundationId = store.state.foundation.selectedFoundation.trid;

    return sendAjaxRequest(
      `/foundation/${foundationId}/contact/${userName}`,
      'GET'
    ).then(
      (res) => {
        if (res._error) {
          throw requestError(res);
        } else {
          return res;
        }
      },
      (error) => {
        throw requestError(error);
      }
    );
  }
}

export default new ContactService();
