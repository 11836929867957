import { sendAjaxRequest, requestError } from '@/services/ajax.service.js';

class FoundationService {
  fetchFoundations() {
    return sendAjaxRequest('/foundation', 'GET', { _activeOnly: true }).then(
      (res) => {
        if (res._error) {
          throw requestError(res);
        } else {
          return res;
        }
      },
      (error) => {
        console.log(error);
        return error;
      }
    );
  }

  searchFoundations(searchString) {
    return sendAjaxRequest('/foundation', 'GET', {
      _search: `or(,${searchString},title:*${searchString},)`,
      _activeOnly: true
    }).then(
      (res) => {
        if (res._error) {
          throw requestError(res);
        } else {
          return res;
        }
      },
      (error) => error
    );
  }
}

export default new FoundationService();
