// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
// import bootstrap and sccs files
import '@/assets/scss/variables.scss';
import '@/assets/scss/custom.scss';
import 'bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'sweetalert2/dist/sweetalert2.css';
import VCalendar from 'v-calendar';
import VeeValidate from 'vee-validate';
import Vue from 'vue';
import VueSweetalert2 from 'vue-sweetalert2';
import Toastr from 'vue-toastr';
import EventBus from '@/helper/eventBus.js';
import App from './App.vue';
import headerEtc from './components/etc/layout/Header.vue';
import headerGnc from './components/layout/Header.vue';
import dataHelper from './helper/dataHelper.js';
import { loader } from './helper/loader.js';
import i18n from './i18n.js';
import { router } from './router/index.js';
import store from './store/index.js';
import * as services from './services/index.js';

// inject the root store into all services depending on it (given they provide an init(store) function)
const keys = Object.keys(services);
keys.forEach((serviceName) => {
  const current = services[serviceName];
  const { init } = current;
  if (
    serviceName !== 'default' &&
    init &&
    typeof init === 'function' &&
    init.length === 1
  ) {
    init(store);
  }
});

// import header and footer component
Vue.component('HeaderContentGnc', headerGnc);
Vue.component('HeaderContentEtc', headerEtc);

// add plugins
Vue.use(VeeValidate);
Vue.use(Toastr);
Vue.use(VCalendar);
Vue.use(VueSweetalert2);

Vue.config.productionTip = false;

// add helper functions
Vue.prototype.$loader = loader;
Vue.prototype.$dataHelper = dataHelper;
Vue.prototype.$eventBus = EventBus;

// add custom directives (new vue events and globally useful shortcuts)
Vue.directive('click-outside', {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  }
});

/* eslint-disable no-new */
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app');
