<template>
  <div class="loader-parent" :class="{ loaderActive: loading }">
    <div
      id="loaderScreen"
      class="loader-container"
      :class="{ loading: loading, faded: backgroundSpec.mode == 'faded' }"
      :style="{
        backgroundColor: backgroundSpecColor,
        boxShadow: `0px 0px 6px 3px ${backgroundSpecColor}`
      }">
      <div id="loader-wrapper" :class="{ fixed: loaderFixed }">
        <div class="loader">
          <div class="loader__bar"></div>
          <div class="loader__bar"></div>
          <div class="loader__bar"></div>
          <div class="loader__bar"></div>
          <div class="loader__bar"></div>
          <div class="loader__ball"></div>
        </div>
      </div>
    </div>
    <div
      class="flex-grow-1"
      :class="{ blurred: backgroundSpec.mode == 'blurred' && loading }">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    loaderFixed: {
      type: Boolean
    },
    // allows for backdrop customization, accepts an Object with mode and options keys. Options may include opacity or backgroundColor for now.
    // backgroundColor has a higher priority than opacity.
    backgroundSpec: {
      type: Object,
      default() {
        return {
          mode: 'faded',
          options: {
            opacity: 0.5
          }
        };
      },
      validator(value) {
        return ['blurred', 'faded', 'none'].includes(value.mode);
      }
    }
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    backgroundSpecColor() {
      if (this.backgroundSpec?.mode == 'faded') {
        if (this.backgroundSpec?.options?.backgroundColor) {
          return this.backgroundSpec.options.backgroundColor;
        }
        if (this.backgroundSpec?.options?.opacity) {
          return `rgba(0,0,0,${this.backgroundSpec.options.opacity})`;
        }
      }
      return null;
    }
  },
  methods: {
    setLoading(state) {
      if (state != this.loading) {
        this.loading = state;
        this.$emit('loadingStateChanged', state);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables';

$spacing: 7px;
$background: rgb(0, 0, 0, 0.57);
$animation-timeout: 0.2s;

.fixed {
  position: fixed;
}

.blurred {
  filter: blur(5px);
  -webkit-filter: blur(5px);
  transition: filter 0.2s;
  -webkit-transition: -webkit-filter 0.2s;
}

.faded {
  background-color: $background;
  box-shadow: 0px 0px 6px 3px $background;
}

.loader-container {
  //align-items: center;
  justify-content: center;
  display: flex;
  position: absolute;
  top: $spacing;
  left: $spacing;
  right: $spacing;
  bottom: $spacing;
  z-index: 1000;
  opacity: 0%;
  visibility: hidden;
  transition: opacity $animation-timeout, visibility $animation-timeout;

  &.loading {
    opacity: 100%;
    visibility: visible;
    transition: opacity $animation-timeout, visibility $animation-timeout;
  }

  #loader-wrapper {
    top: 0px;
    bottom: 0px;
    align-items: center;
    display: flex;
    //min-height: 100%;
    max-height: 100vh;

    &.fixed {
      top: 0px;
      bottom: 0px;
      max-height: initial;
      min-height: initial;
    }
  }
}

.loader-parent {
  position: relative;
  overflow: hidden;
  margin: -$spacing;
  padding: $spacing;
  transition: all 0.3s ease-in-out;
  min-height: 0px;
  &.loaderActive {
    min-height: 100px;
    transition: all 0.3s ease-in-out;
  }
}

// Variables
$bar-color: $secondary;
$ball-color: $secondary;

.loader {
  position: relative;
  //left: calc(100% / 2 - 37.5px);
  // top: calc(100% / 2 - 50px);
  width: 75px;
  height: 50px;

  &__bar {
    position: absolute;
    bottom: 0;
    width: 10px;
    height: 100%;
    background: $bar-color;
    transform-origin: center bottom;
    box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);

    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        left: ($i - 1) * 15px;
        transform: scale(1, $i * 0.2);
        animation: barUp#{$i} 4s infinite;
      }
    }
  }

  &__ball {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 10px;
    height: 10px;
    background: $ball-color;
    border-radius: 50%;
    animation: ball 4s infinite;
  }
}

.loader > * {
  box-shadow: 0px 0px 30px 5px rgba(118, 83, 243, 0.15); //slight drop shadow for animation
}

@keyframes ball {
  0% {
    transform: translate(0, 0);
  }
  5% {
    transform: translate(8px, -14px);
  }
  10% {
    transform: translate(15px, -10px);
  }
  17% {
    transform: translate(23px, -24px);
  }
  20% {
    transform: translate(30px, -20px);
  }
  27% {
    transform: translate(38px, -34px);
  }
  30% {
    transform: translate(45px, -30px);
  }
  37% {
    transform: translate(53px, -44px);
  }
  40% {
    transform: translate(60px, -40px);
  }
  50% {
    transform: translate(60px, 0);
  }
  57% {
    transform: translate(53px, -14px);
  }
  60% {
    transform: translate(45px, -10px);
  }
  67% {
    transform: translate(37px, -24px);
  }
  70% {
    transform: translate(30px, -20px);
  }
  77% {
    transform: translate(22px, -34px);
  }
  80% {
    transform: translate(15px, -30px);
  }
  87% {
    transform: translate(7px, -44px);
  }
  90% {
    transform: translate(0, -40px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes barUp1 {
  0% {
    transform: scale(1, 0.2);
  }
  40% {
    transform: scale(1, 0.2);
  }
  50% {
    transform: scale(1, 1);
  }
  90% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 0.2);
  }
}
@keyframes barUp2 {
  0% {
    transform: scale(1, 0.4);
  }
  40% {
    transform: scale(1, 0.4);
  }
  50% {
    transform: scale(1, 0.8);
  }
  90% {
    transform: scale(1, 0.8);
  }
  100% {
    transform: scale(1, 0.4);
  }
}
@keyframes barUp3 {
  0% {
    transform: scale(1, 0.6);
  }
  100% {
    transform: scale(1, 0.6);
  }
}
@keyframes barUp4 {
  0% {
    transform: scale(1, 0.8);
  }
  40% {
    transform: scale(1, 0.8);
  }
  50% {
    transform: scale(1, 0.4);
  }
  90% {
    transform: scale(1, 0.4);
  }
  100% {
    transform: scale(1, 0.8);
  }
}
@keyframes barUp5 {
  0% {
    transform: scale(1, 1);
  }
  40% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 0.2);
  }
  90% {
    transform: scale(1, 0.2);
  }
  100% {
    transform: scale(1, 1);
  }
}
</style>
