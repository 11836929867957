<template>
  <footer class="container-fluid bg-light d-flex align-items-center">
    <span class="flex-grow-1 footer-copyright">
      <slot name="left" />
    </span>
    <span class="footer-right">
      <slot name="right" />
    </span>
  </footer>
</template>

<script>
export default {
  name: 'PortalFooter'
};
</script>

<style lang="scss" scoped>
footer {
  padding: 0;
  font-weight: 600;
  font-family: 'Lato';

  .footer-copyright {
    font-size: 16px;
    font-weight: 700;
    padding: 29px;
  }

  .footer-right {
    font-weight: 800;
    font-size: 16px;
    padding-right: 29px;
  }
}
</style>
