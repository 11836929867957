import OrderService from '@/services/order.service.js';

const initState = { orders: null, selectedOrder: null };

export default {
  namespaced: true,
  state: initState,
  actions: {
    getOrders({ commit, dispatch }, payload = {}) {
      return OrderService.getOrderList(payload).then(
        (orders) => {
          commit('SET_ORDERS', orders);
          return Promise.resolve(orders);
        },
        (error) => {
          dispatch('reset');
          return Promise.resolve(error);
        }
      );
    },
    setSelectedOrder({ commit, state }, orderId) {
      const selectedOrder = state.orders?.find((order) => order.id === orderId);

      commit('SET_SELECTED_ORDER', selectedOrder);
    },
    reset({ commit }) {
      commit('RESET_ORDER');
    }
  },
  mutations: {
    SET_ORDERS(state, orders) {
      state.orders = orders;
    },
    SET_SELECTED_ORDER(state, selectedOrder) {
      state.selectedOrder = selectedOrder;
    },
    RESET_ORDER(state) {
      Object.assign(state, initState);
    }
  }
};
