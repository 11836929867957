import { sendAjaxRequest, requestError } from '@/services/ajax.service.js';

let store;
export function init(globalStore) {
  store = globalStore;
}

class DraftService {
  getDraftList(parameter = {}) {
    let searchPrefix = '';

    if (parameter.showArchived) {
      searchPrefix = `archived:true ${searchPrefix}`;
    }
    if (parameter.filterCustomerId) {
      searchPrefix = `value5:${parameter.filterCustomerId} ${searchPrefix}`;
    }

    const data = { search: searchPrefix, type: 'hfo.vp.draft' };

    if (parameter.selectDraftId) {
      data._selected = parameter.selectDraftId;
    }

    if (parameter.searchString) {
      data.search += parameter.searchString;
    }

    const foundationId = store.state.foundation.selectedFoundation.trid;

    return sendAjaxRequest(
      `/foundation/${foundationId}/data`,
      'GET',
      data
    ).then(
      (res) => {
        if (res._error) {
          throw requestError(res);
        } else {
          return res;
        }
      },
      (error) => error
    );
  }
}

export default new DraftService();
