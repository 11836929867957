import AuthService from '../services/auth.service.js';

const initState = {
  status: { loggedIn: false },
  needsToS: false,
  user: null,
  contact: null
};
let refreshTokenTimer = null;

export default {
  namespaced: true,
  state: initState,
  actions: {
    login({ commit, getters, dispatch }, user) {
      return AuthService.login(user).then(
        async (res) => {
          commit('loginSuccess', res);
          console.log(
            `Will refresh in ${getters.tokenRefreshTimeoutSeconds} seconds`
          );
          if (refreshTokenTimer) {
            clearTimeout(refreshTokenTimer);
            refreshTokenTimer = null;
          }
          refreshTokenTimer = setTimeout(
            () => {
              dispatch('refreshToken');
            },
            getters.tokenRefreshTimeout
            // refresh ~ 15min (at 1h token time span) before token becomes stale
          );
          await dispatch('foundation/fetchFoundations', null, { root: true });
          return Promise.resolve(res);
        },
        (error) => {
          dispatch('logout');
          return Promise.reject(error);
        }
      );
    },
    refreshToken({ commit, dispatch, getters }) {
      // noticed during token refresh debugging: Seemingly never used?
      return AuthService.refreshToken().then(
        (user) => {
          commit('loginSuccess', user);
          refreshTokenTimer = setTimeout(
            () => {
              dispatch('refreshToken');
            },
            getters.tokenRefreshTimeout
            // refresh ~ 15min (at 1h token time span) before token becomes stale
          );
          return Promise.resolve(user);
        },
        (error) => {
          dispatch('logout');
          clearTimeout(refreshTokenTimer);
          refreshTokenTimer = null;
          return Promise.reject(error);
        }
      );
    },
    logout({ commit, dispatch }) {
      commit('logoutSuccess');
      dispatch('customer/reset', null, { root: true });
      dispatch('draft/reset', null, { root: true });
      dispatch('foundation/reset', null, { root: true });
      dispatch('order/reset', null, { root: true });
      dispatch('ticket/reset', null, { root: true });
      dispatch('wholeSaleCustomer/reset', null, { root: true });
      clearTimeout(refreshTokenTimer);
    },
    invalidateToS({ commit }) {
      commit('changeTosStatus', true);
    },
    acceptToS({ commit }) {
      commit('changeTosStatus', false);
    }
  },
  mutations: {
    changeTosStatus(state, needed) {
      state.needsToS = needed;
    },
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    logoutSuccess(state) {
      state.user = null;
      state.status.loggedIn = false;
    },
    setContact(state, contact){
      state.contact = contact;
    }
  },
  getters: {
    tokenRefreshTimeout(state) {
      return (state.user._timestamp - Date.now()) * 0.75;
    },
    tokenRefreshTimeoutSeconds(state, getters) {
      return getters.tokenRefreshTimeout / 1000;
    },
    hasRole: (state, getters, rootState) => (allowedRoles) => {
      const grantedRoles = [];

      state.user?.roles?.forEach((role) => {
        grantedRoles.push(role.toLowerCase());
      });

      const foundationRoles =
        rootState.foundation?.selectedFoundation?.type?.split(',');

      foundationRoles?.forEach((foundationRole) => {
        grantedRoles.push(foundationRole.toLowerCase());
      });

      if (Array.isArray(allowedRoles)) {
        for (let i = 0; allowedRoles.length; i++) {
          const allowedRole = allowedRoles[i];
          if (grantedRoles.includes(allowedRole.toLowerCase())) {
            return true;
          }
        }
      } else {
        if (grantedRoles.includes(allowedRoles.toLowerCase())) {
          return true;
        }
        return false;
      }
      return false;
    }
  }
};
