import { router } from '@/router/index.js';

const forbiddenRoutes = [];

const validations = {
  // contains all validations that will be executed once handleRedirect is called. Add further functions here if needed.
  routeExists: (source) => {
    const { resolved } = router.resolve({ path: source.query?.redirect });
    return resolved.matched.length > 0 && resolved.name != '404';
  },
  isAllowed: (source) => !forbiddenRoutes.includes(source.path),
  isDecoded: (source) =>
    decodeURIComponent(source.query?.redirect) === source.query?.redirect
};

export function handleRedirect(source, def) {
  if (!('redirect' in source.query)) {
    router.push(def);
    return true;
  }
  // execute all validations
  const props = Object.keys(validations);
  for (let i = 0; i < props.length; i++) {
    const prop = props[i];
    if (!validations[prop](source)) {
      if (def) {
        // stay on the same page we were already at if def is undefined
        router.push(def);
      }
      return false;
    }
  }

  const next = source.query.redirect;
  // ensure the same source object will not cause redirect side effects further down the chain...
  // eslint-disable-next-line no-param-reassign
  delete source.query.redirect;
  router.push({ path: next, query: source.query, hash: source.hash });
  return true;
}

export function getPathFromHash(hash) {
  let res = hash;
  if (!res) return res;
  if (res.startsWith('#!d:')) res = hash.substring(4);
  return decodeURIComponent(res);
}
