import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';

import auth from './auth.module.js';
import foundation from './foundation.module.js';
import customer from './customer.module.js';
import order from './order.module.js';
import draft from './draft.module.js';
import ticket from './ticket.module.js';
import contact from './contact.module.js';
import wholeSaleCustomer from './wholeSaleCustomer.module.js';

Vue.use(Vuex);

const ls = new SecureLS({ encodingType: 'aes' });

// For Debug use this instead of line 17 and clear complete local storage
// var ls = new SecureLS({encodingType: '', isCompression: false});

export default new Vuex.Store({
  modules: {
    auth,
    foundation,
    customer,
    draft,
    order,
    ticket,
    contact,
    wholeSaleCustomer
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key)
      }
    })
  ]
});
