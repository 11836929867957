import DraftService from '../services/draft.service.js';

const initState = { drafts: null, selectedDraft: null };

export default {
  namespaced: true,
  state: initState,
  actions: {
    getDrafts({ commit, dispatch }, payload = {}) {
      return DraftService.getDraftList(payload).then(
        (drafts) => {
          commit('SET_DRAFTS', drafts);
          return Promise.resolve(drafts);
        },
        (error) => {
          dispatch('reset');
          return Promise.resolve(error);
        }
      );
    },
    setSelectedDraft({ commit, state }, draftId) {
      const selectedDraft = state.draft?.drafts?.find(
        (draft) => draft.id === draftId
      );

      commit('SET_SELECTED_DRAFT', selectedDraft);
    },
    reset({ commit }) {
      commit('RESET_DRAFT');
    }
  },
  mutations: {
    SET_DRAFTS(state, drafts) {
      state.drafts = drafts;
    },
    SET_SELECTED_DRAFT(state, selectedDraft) {
      state.selectedDraft = selectedDraft;
    },
    RESET_DRAFT(state) {
      Object.assign(state, initState);
    }
  }
};
