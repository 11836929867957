/* eslint-disable global-require */ // disable linting for inline require()-calls for now until we've found a suitable solution
const entityMap = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#39;',
  '/': '&#x2F;',
  '`': '&#x60;',
  '=': '&#x3D;'
};

export default {
  ticket: {
    types: {
      etcCallBackRequest: {
        type: 'etcCallBackRequest',
        caption: 'Rückrufbitte'
      },

      etcProjectRequest: {
        type: 'etcProjectRequest',
        caption: 'Angebotsanfrage'
      },
      etcProvisionComplain: {
        type: 'etcProvisionComplain',
        caption: 'Provisionsreklamation'
      }
    }
  },
  vo: {
    voNameMapping: {
      CONGSTAR_GMBH: 'Congstar GmbH',
      EPSILON_TELECOMMUNICATIONS_GMBH: 'Epsilon Telecommunications GmbH',
      'TELEFONICA_GERMANY_GMBH___CO._OHG': 'Telefonica Germany GmbH & Co. OHG',
      'TELEFONICA_GERMANY_GMBH_&_CO._OHG': 'Telefonica Germany GmbH & Co. OHG',
      'TELEKOM_DEUTSCHLAND_GMBH_(TMD_DIREKT)':
        'Telekom Deutschland GmbH (TMD direkt)',
      TELEKOM_DEUTSCHLAND_GMBH: 'Telekom Deutschland GmbH',
      TELEKOM_GK_FESTNETZ: 'Telekom GK Festnetz',
      'TELEKOM_PK_FESTNETZ_(T-HOME_DIREKT)':
        'Telekom PK Festnetz (T-Home direkt)',
      TEQCYCLE_SOLUTIONS_GMBH: 'Teqcycle Solutions GmbH',
      VODAFONE_GMBH: 'Vodafone GmbH ',
      WERTGARANTIE_AG: 'Wertgarantie AG',
      BRODOS_AG: 'Brodos AG',
      FLEXO_KOMMUNIKATIONSDIENSTE_GMBH: 'FLEXO Kommunikationsdienste GmbH',
      HFO_TELECOM_VERTRIEBS_GMBH: 'Telecom Vertriebs GmbH',
      ISEC7_GMBH: 'ISEC7 GmbH',
      'K-TEL_COMMUNICATIONS_GMBH': 'K-Tel Communications GmbH',
      TRUPHONE_GMBH: 'Truphone GmbH',
      UNITYMEDIA_NRW_GMBH: 'Unitymedia NRW GmbH',
      WIRELESS_LOGIC_GMBH: 'Wireless Logic GmbH',
      AREALCONTROL_GMBH: 'Arealcontrol GmbH',
      MMV_LEASING_GMBH: 'MMV Leasing GmbH'
    },
    mapName(technical) {
      let name = this.voNameMapping[technical];
      if (!name) {
        name = technical.replace(/_/g, ' ');
      }
      return name;
    }
  },
  products: {
    Hfo360Type: {
      // eslint-disable-next-line
      caption: function (data) {
        return '360°';
      },
      type: 'hfo360',
      creatable: true,
      bookable: true,
      ext_tariff_voip_isBackuprouting: false,
      form: 'NgnOrderForm'
    },
    HfoNgnConnectType: {
      caption(data) {
        return `NGN Connect${
          data &&
          (data.ext_tariff_dsl_isAdditional === true ||
            data.ext_tariff_dsl_isAdditional === 'true')
            ? '+DSL'
            : ''
        }`;
      },
      type: 'hfoNgnConnect',
      creatable: true,
      bookable: true,
      ext_tariff_voip_isBackuprouting: false,
      form: 'NgnOrderForm'
    },
    HfoNgnTeamsConnectType: {
      // eslint-disable-next-line
      caption: function (data) {
        return 'NGN Teams Connect';
      },
      type: 'hfoNgnTeamsConnect',
      creatable: true,
      bookable: true,
      tariff_voip_maxchannels_connect: '',
      ext_tariff_voip_isBackuprouting: false,
      form: 'NgnOrderForm'
    },
    HfoNgnBasicType: {
      // eslint-disable-next-line
      caption: function (data) {
        return 'NGN Basic';
      },
      type: 'hfoNgnBasic',
      creatable: true,
      bookable: true,
      ext_tariff_voip_maxchannls: 4,
      maxchannels: [4],
      ext_tariff_voip_isBackuprouting: false,
      form: 'NgnOrderForm'
    },
    HfoNgnBusinessType: {
      // eslint-disable-next-line
      caption: function (data) {
        return 'NGN Business (läuft zum 31.03.2019 aus)';
      },
      type: 'hfoNgnBusiness',
      creatable: false,
      bookable: false,
      maxchannels: [6, 8, 25, 50, 120],
      ext_tariff_voip_isBackuprouting: false,
      form: 'NgnOrderForm'
    },
    HfoIsdnBusinessType: {
      // eslint-disable-next-line
      caption: function (data) {
        return 'ISDN Business (läuft zum 31.03.2019 aus)';
      },
      type: 'hfoIsdnBusiness',
      creatable: false,
      bookable: false,
      maxchannels: [4, 6, 8, 25],
      ext_tariff_voip_isBackuprouting: false,
      form: 'NgnOrderForm'
    },
    HfoNgnOneBusinessType: {
      // eslint-disable-next-line
      caption: function (data) {
        return 'NGN ONE Business';
      },
      type: 'hfoNgnOneBusiness',
      creatable: true,
      bookable: true,
      maxchannels: [4, 6, 8, 12, 20, 30, 60, 90, 120],
      ext_tariff_voip_isBackuprouting: true,
      manufacturers: [
        '3CX',
        'Aethra',
        'Agfeo',
        'Alcatel-Lucent',
        'ansit-com',
        'Audiocodes',
        'Auerswald',
        'AVM',
        'Beronet',
        'Bintec-Elmeg',
        'Cisco / Linksys / Sipura',
        'DATUS',
        'Gigaset',
        'innovaphone',
        'IPTAM',
        'Mitel (Aastra)',
        'Panasonic',
        'Pascom',
        'Patton Inalp',
        'Snom',
        'Starface',
        'Swyx',
        'Telekom (Arcadyan)',
        'Teles',
        'tevitel',
        'Tiptel',
        'UCWare (Bel Net)',
        'Unify',
        'Wildix',
        'Yealink',
        'Yeastar'
      ],
      form: 'NgnOrderForm'
    },
    HfoDslMaxxBusinessType: {
      // eslint-disable-next-line
      caption: function (data) {
        return 'DSLmaxx Business';
      },
      type: 'hfoDslMaxxBusiness',
      creatable: true,
      bookable: true,
      ext_tariff_voip_isBackuprouting: false,
      form: 'NgnOrderForm'
    },
    HfoCrownCentrexType: {
      // eslint-disable-next-line
      caption: function (data) {
        return 'Crown Centrex';
      },
      type: 'hfoCrownCentrex',
      creatable: true,
      bookable: true,
      ext_tariff_voip_maxchannels: '',
      maxchannels: [1, 2, 4, 6, 8, 10, 25],
      ext_tariff_voip_isBackuprouting: false,
      form: 'CrownCentrexOrderForm'
    },
    HfoBusinessHomeMobile: {
      // eslint-disable-next-line
      caption: function (data) {
        return 'Business & Home Mobile';
      },
      type: 'hfoBusinessHomeMobile',
      creatable: true,
      bookable: true,
      ext_tariff_voip_isBackuprouting: false,
      form: 'MobileOrderForm'
    }
  },
  networkOperators: {
    // TODO: Fill network operators
    'Epsilon FUSION IoT': {
      id: 'epsilon-fusion-iot', // Muss einmalig sein (kann frei ausgedacht sein, wird aber in der URL als Parameter angezeigt)
      caption: 'Epsilon FUSION IoT', // Wird als Text angezeigt
      fileBrowserPath: 'Epsilon_Fusion_IOT', // Wird für den FileBrowser als Pfad benötigt
      logo: require('@/assets/images/png/NetworkOperatorLogos/Fusion-IoT_Logo.png'), // Logo
      title: 'Epsilon Telecom GmbH',
      street: 'Ziegeleistr. 2',
      location: '95145 Oberkotzau',
      website: 'www.fusion-iot.de',
      url: 'https://fusion-iot.de',
      phone1_text: 'Partner-Hotline',
      phone1: '09286 9404-480',
      phone2_text: 'Endkunden-Hotline',
      phone2: '0800 9900909',
      phone3_text: '',
      phone3: '',
      phone4_text: '',
      phone4: '',
      phone5_text: '',
      phone5: '',
      team_phone: '09286 9404-480',
      team_mail: 'support@fusion-iot.de',
      message:
        'Im Zuge der Digitalen Revolution hat die EPSILON die Marke FUSION IoT entwickelt um Sie als direkter Ansprechpartner zu den Themen Internet of Things, Industrie 4.0 oder M2M zu unterstützen. FUSION IoT ist eine multidirektionale SIM-Management-Plattform, die direkt an die IoT- & M2M-Lösungen aller drei Netzbetreiber in Deutschland angebunden wird. Sie garantiert Unternehmen den leichten Einstieg in digitale Prozesse durch eine einfache Verwaltung, völlige Transparenz und höchster Flexibilität.',
      links: {
        link1: {
          text: 'IOT  Kundenportal',
          link: 'https://portal.fusion-iot.de/sign/in?backlink=ks6fj'
        },
        link2: {
          text: 'IOT  Partnerportal',
          link: 'https://partner.fusion-iot.de/sign/in?backlink=74wos'
        },
        link3: {
          text: 'Epsilon',
          link: 'https://www.epsilon-telecom.de/'
        }
      },
      role: 'FIOT'
    },

    Epsilon: {
      id: 'epsilon', // Muss einmalig sein (kann frei ausgedacht sein, wird aber in der URL als Parameter angezeigt)
      caption: 'Epsilon Telecom GmbH', // Wird als Text angezeigt
      fileBrowserPath: 'Epsilon', // Wird für den FileBrowser als Pfad benötigt
      logo: require('@/assets/images/png/NetworkOperatorLogos/Epsilon_Logo.png'), // Logo
      title: 'Epsilon Telecom GmbH',
      street: 'Ziegeleistr. 2',
      location: '95145 Oberkotzau',
      website: 'www.epsilon-telecom.de',
      url: 'https://epsilon-telecom.de',
      phone1_text: 'Vertriebsassistenz',
      phone1: '09286 9404-400',
      phone2_text: 'Marketing',
      phone2: '09286 9404-490',
      phone3_text: 'Akademie',
      phone3: '09286 9404-490',
      phone4_text: 'Hardware',
      phone4: '09286 9404-499',
      phone5_text: '',
      phone5: '',
      team_phone: '09286 9404-400',
      team_mail: 'vertriebsassistenz@epsilon-telecom.de',
      message:
        'Partnerschaften und Kooperationen mit den führenden Partnern der deutschen Telekommunikationsbranche. Einen Jahresumsatz von 28,5 Millionen Euro (Stand 2018), eine durchgehend profitable Ertragssituation seit Firmengründung 1997, 150.000 Mobilfunk-Verträge für Geschäftskunden, 30.000 aktive M2M-Karten & Projektvolumen bis Ende 2020 von 500.000 M2M-Karten – das ist die Epsilon Telecommunications GmbH - größter Servicedienstleister für den deutschen Mittelstans und Ansprechpartner für über 600 aktive Vertriebspartner.',
      links: {
        link1: {
          text: 'Zur Homepage',
          link: 'https://www.epsilon-telecom.de'
        },
        link2: {
          text: 'Fusion  IOT',
          link: 'https://www.fusion-iot.de'
        },
        link3: {
          text: 'IOT  Partnerportal',
          link: 'https://partner.fusion-iot.de/sign/in?backlink=74wos'
        }
      },
      role: 'ETC'
    },
    MMV_Leasing: {
      id: 'MMV_Leasing', // Muss einmalig sein (kann frei ausgedacht sein, wird aber in der URL als Parameter angezeigt)
      caption: 'MMV Leasing GmbH Niederlassung Bayreuth', // Wird als Text angezeigt
      fileBrowserPath: 'MMV_Leasing', // Wird für den FileBrowser als Pfad benötigt
      logo: require('@/assets/images/png/NetworkOperatorLogos/Logo MMV Leasing_quer.png'), // Logo
      title: 'MMV Leasing GmbH Niederlassung Bayreuth',
      street: 'Casselmannstraße 4',
      location: '95444 Bayreuth',
      website: 'www.mmv-leasing.de',
      url: 'https://www.mmv-leasing.de',
      phone1_text: 'Distriktleiter Stephan Sticht',
      phone1: '0921 78914-14',
      phone2_text: 'Partner- & Kundensupport',
      phone2: '0921 78914-11',
      phone3_text: '',
      phone3: '',
      phone4_text: '',
      phone4: '',
      phone5_text: '',
      phone5: 'bayreuth@mmv-leasing.de',
      team_phone: '09286 9404-480',
      team_mail: 'ab@epsilon-telecom.de',
      message:
        'Die MMV Gruppe - sie vereint Finanzierungslösungen aller Art unter sich. Ob technische Innovation für das Büro oder in der Medizintechnik, ob Finanzierungslösungen für Gebäudetechnologie, Mobilität oder im Nahrungsmittelbereich -  die MMV Gruppe als eine der ersten und renommiertesten Leasinggesellschaften in Deutschland für mobile Wirtschaftsgüter ist der traditionelle Partner des Mittelstandes.  Mehr als 50.000 Kunden, rund 430 Mitarbeiter an bundesweiten Standorten, Geschäftspartner bei Investitionen von rund 750 Millionen Euro - seit über sechs Jahrzehnten.',
      role: 'MMV'
    },
    Arealcontrol: {
      id: 'Arealcontrol', // Muss einmalig sein (kann frei ausgedacht sein, wird aber in der URL als Parameter angezeigt)
      caption: 'Arealcontrol', // Wird als Text angezeigt
      fileBrowserPath: 'Arealcontrol', // Wird für den FileBrowser als Pfad benötigt
      logo: require('@/assets/images/png/NetworkOperatorLogos/ArealControl-Logo.png'), // Logo
      title: 'AREALCONTROL GmbH',
      street: 'Strohberg 1',
      location: '70180 Stuttgart',
      website: 'www.arealcontrol.de',
      url: 'https://www.arealcontrol.de',
      phone1_text: 'Hotline',
      phone1: '0711 6017930',
      phone2_text: '',
      phone2: '',
      phone3_text: '',
      phone3: '',
      phone4_text: '',
      phone4: '',
      phone5_text: '',
      phone5: 'office@arealcontrol.de',
      team_phone: '09286 9404-480',
      team_mail: 'ab@epsilon-telecom.de',
      message:
        'Das im Jahr 2003 gegründete Unternehmen AREALCONTROL GmbH, bietet GPS Ortungs-, Telematik-, Tracking-Produkte & Services für Transport, Logistik, Gewerbe, Handel, Handwerk und Industrie. Der deutsche Telematik Preis 2018 in der Kategorie Telematik für Service-Pkw wurde bei erstmaliger Teilnahme mit Platz 1 belegt.',
      role: 'AC'
    },
    Telekom: {
      id: 'Telekom', // Muss einmalig sein (kann frei ausgedacht sein, wird aber in der URL als Parameter angezeigt)
      caption: 'Telekom', // Wird als Text angezeigt
      fileBrowserPath: 'Telekom', // Wird für den FileBrowser als Pfad benötigt
      logo: require('@/assets/images/png/NetworkOperatorLogos/Telekom-Logo_magenta.jpg'), // Logo
      title: 'Telekom Deutschland GmbH',
      street: 'Landgrabenweg 151',
      location: '53227 Bonn',
      website: 'www.telekom.de',
      url: 'https://www.telekom.de',
      phone1_text: 'Festnetz Business Partner',
      phone1: '0800 3305440-4',
      phone2_text: 'Festnetz Vertriebshotline T-VPP',
      phone2: '0800 3305440-2',
      phone3_text: 'Mobilfunk Service-Center GK',
      phone3: '0800 3300345-01',
      phone4_text: 'Mobilfunk BSP Hotline',
      phone4: '0800 3355540',
      phone5_text: 'Mobilfunk Boni GK',
      phone5: '0800 5333250',
      team_phone: '',
      team_mail: '',
      message:
        'Die Deutsche Telekom gehört mit rund 178 Millionen Mobilfunk-Kunden, 28 Millionen Festnetz- und 20 Millionen Breitband-Anschlüssen zu den führenden integrierten Telekommunikations-Unternehmen weltweit. Beschäftigt 216.000 Mitarbeiter und hat einen Umsatz von 75,7 Milliarden Euro (2018) weltweit in mehr als 50 Ländern. Zwischen der Telekom und Epsilon besteht eine jahrzehntelange enge Partnerschaft als PremiumPlus Partner.',
      links: {
        link1: {
          text: 'Telekom Business Service Portal (BSP)',
          link: 'https://bsp.t-mobile.de/portal'
        },
        link2: {
          text: 'Vertriebspartner-Portal (TVPP)',
          link: 'https://vertriebspartner.telekom.de'
        },
        link3: {
          text: 'BusinessPartner Portal (BPP)',
          link: 'https://business-partner-portal.telekom.de'
        }
      },
      role: 'TK'
    },
    Wireless_Logic: {
      id: 'Wireless_Logic', // Muss einmalig sein (kann frei ausgedacht sein, wird aber in der URL als Parameter angezeigt)
      caption: 'Wireless Logic GmbH', // Wird als Text angezeigt
      fileBrowserPath: 'Wireless_Logic', // Wird für den FileBrowser als Pfad benötigt
      logo: require('@/assets/images/png/NetworkOperatorLogos/WirelessLogic_Logo.png'), // Logo
      title: 'Wireless Logic GmbH',
      street: 'Am Hochacker 4',
      location: '85630 Grasbrunn',
      website: 'www.wirelesslogic.de',
      url: 'https://www.wirelesslogic.de',
      phone1_text: 'Support Hotline',
      phone1: '089 55062139 ',
      phone2_text: '',
      phone2: '',
      phone3_text: '',
      phone3: '',
      phone4_text: '',
      phone4: '',
      phone5_text: '',
      phone5: 'support@wirelesslogic.de',
      team_phone: '09286 9404-480',
      team_mail: 'ab@epsilon-telecom.de',
      message:
        'Das im Jahr 2000 gegründete Unternehmen Wireless Logic verbindet die digitale Welt Gerät für Gerät. Über 3,1 Millionen Abonnenten in 60 Ländern werden von Wireless Logic und seinen über 600 Partnerschaften weltweit mit der optimalen Lösung versorgt. Zu den Kunden zählen Systemintegratoren, Entwickler und Technologieunternehmen, OEMs und globale Blue-Chip-Unternehmen.',
      links: {
        link1: {
          text: 'SIMPro Portal',
          link: 'https://simpro4.wirelesslogic.com/en/login'
        }
      },
      role: 'WL'
    },
    Wertgarantie: {
      id: 'Wertgarantie', // Muss einmalig sein (kann frei ausgedacht sein, wird aber in der URL als Parameter angezeigt)
      caption: '', // Wird als Text angezeigt
      fileBrowserPath: 'Wertgarantie', // Wird für den FileBrowser als Pfad benötigt
      logo: require('@/assets/images/png/NetworkOperatorLogos/WERTGARANTIE_Logo.png'), // Logo
      title: 'Wertgarantie AG',
      street: 'Breite Str. 8',
      location: '30159 Hannover',
      website: 'www.wertgarantie.de',
      url: 'https://www.wertgarantie.de',
      phone1_text: 'Fachhändlerbetreuung',
      phone1: ' 0511 71280-111',
      phone2_text: 'Kundenbetreuung',
      phone2: '0511 71280-123',
      phone3_text: 'Schadenbearbeitung Kommunikation',
      phone3: '0511 71280-881',
      phone4_text: '',
      phone4: '',
      phone5_text: '',
      phone5: '',
      team_phone: '09286 9404-480',
      team_mail: 'ab@epsilon-telecom.de',
      message:
        'WERTGARANTIE ist Deutschlands Fachhandelspartner Nr. 1 bei Garantie-Dienstleistungen für Konsumelektronik, Haushaltsgeräte und Fahrräder. Seit 1963 werden einzigartige Rundum-Versicherungen angeboten. Von den maßgeschneiderten Lösungen profitieren mehr als 6 Millionen Kunden der Unternehmensgruppe in Deutschland und weiteren Ländern Europas sowie rund 6.500 Fachhandelspartner.',
      links: {
        link1: {
          text: 'Partnerportal',
          link: 'https://partner.wertgarantie.de/home-b2b/Themen/fh-portal.aspx'
        },
        link2: {
          text: 'Schadensportal',
          link: 'https://selfservice.wertgarantie.de/backend/login'
        }
      },
      role: 'WG'
    },
    Vodafone: {
      id: 'Vodafone', // Muss einmalig sein (kann frei ausgedacht sein, wird aber in der URL als Parameter angezeigt)
      caption: 'Vodafone', // Wird als Text angezeigt
      fileBrowserPath: 'Vodafone', // Wird für den FileBrowser als Pfad benötigt
      logo: require('@/assets/images/png/NetworkOperatorLogos/Vodafone-Logo.png'), // Logo
      title: 'Vodafone GmbH',
      street: 'Ferdinand-Braun-Platz 1',
      location: '40549 Düsseldorf',
      website: 'www.vodafone.de',
      url: 'https://www.vodafone.de',
      phone1_text: 'Geschäftskundenhotline',
      phone1: ' 0800 1721234',
      phone2_text: '',
      phone2: '',
      phone3_text: '',
      phone3: '',
      phone4_text: '',
      phone4: '',
      phone5_text: '',
      phone5: '',
      team_phone: '09286 9404-450',
      team_mail: 'ab@epsilon-telecom.de',
      message:
        'Vodafone Deutschland bietet mit Mobilfunk, Festnetz, Internet und TV innovative Technologie- und Dienstleistungen an, die nach Kundenwünschen zusammengestellt werden. Seit 1990 bietet Vodafone seine Dienste an und ist mit 47 Mio. geschalteten SIM-Karten (Stand: 4. Quartal 2018) der größte Mobilfunkanbieter in Deutschland mit einem Umsatz von 10 Milliarden Euro.',
      links: {
        link1: {
          text: 'Verfügbarkeitsprüfung Festnetz',
          link: 'https://dslshop.vodafone.de/eshop/business/enterprise-locator/index.html'
        },
        link2: {
          text: 'Enterprise Partner Portal',
          link: 'https://epp.vodafone.de/'
        }
      },
      role: 'VF'
    },
    'O2-Business': {
      id: 'O2Business',
      caption: 'O2-Business',
      fileBrowserPath: 'O2_Business',
      logo: require('@/assets/images/png/NetworkOperatorLogos/o2_Business_Logo.png'),
      title: 'Telefonica Germany GmbH & Co. OHG',
      street: 'Georg-Brauchle-Ring 50',
      location: '80992 München',
      website: 'www.o2.de',
      url: 'https://www.o2.de',
      phone1_text: 'Business Team',
      phone1: '0800 2211122',
      phone2_text: '',
      phone2: '',
      phone3_text: '',
      phone3: '',
      phone4_text: '',
      phone4: '',
      phone5_text: '',
      phone5: '',
      team_phone: '09286 9404-460',
      team_mail: 'ab@epsilon-telecom.de',
      message:
        'Rund um das Thema Telekommunikation ist die o2 weitgefächert aufgestellt und gehört mit rund 45,1 Millionen Mobilfunkanschlüssen und 4,3 Millionen Festnetzanschlüsse zu den führenden integrierten Telekommunikationsunternehmen in Deutschland. Der kombinierte Umsatz lag 2018 bei 7,3 Milliarden Euro. Handys, Tarife, DSL & Festnetz, TV & Apps & Zubehör sowie umfangreicher Service gehören zu ihrem breiten Angebot.',
      links: {
        link1: {
          text: 'o2 Partner Online System (PARTOS)',
          link: 'https://www.vertriebspartner.de.o2.com'
        }
      },
      role: 'TFG'
    },
    Congstar: {
      id: 'Congstar', // Muss einmalig sein (kann frei ausgedacht sein, wird aber in der URL als Parameter angezeigt)
      caption: 'Congstar', // Wird als Text angezeigt
      fileBrowserPath: 'Congstar', // Wird für den FileBrowser als Pfad benötigt
      logo: require('@/assets/images/png/NetworkOperatorLogos/Congstar_Logo_100.png'), // Logo
      title: 'congstar – eine Marke der Telekom Deutschland GmbH',
      street: 'Landgrabenweg 151',
      location: '53227 Bonn',
      website: 'www.congstar.de',
      url: 'https://www.congstar.de',
      phone1_text: 'Händler-Hotline',
      phone1: ' 0221 79700201',
      phone2_text: 'Kunden-Hotline',
      phone2: '0221 79700700',
      phone3_text: '',
      phone3: '',
      phone4_text: '',
      phone4: '',
      phone5_text: '',
      phone5: '',
      team_phone: '09286 9404-440',
      team_mail: 'ab@epsilon-telecom.de',
      message:
        'Congstar ist die Zweitmarke der Deutschen Telekom. Ein flexibles Tarifsystem bietet Millionen Kunden die optimale zugeschneiderte Lösung an. Über Congstar können Handys & Tablets und verschiedene Internetlösungen bezogen werden.',
      links: {
        link1: {
          text: 'Vertriebsportale',
          link: 'https://vertriebsportal.congstar.de/'
        }
      },
      role: 'CS'
    },
    Truephone: {
      id: 'Truephone', // Muss einmalig sein (kann frei ausgedacht sein, wird aber in der URL als Parameter angezeigt)
      caption: 'Truephone', // Wird als Text angezeigt
      fileBrowserPath: 'Truephone', // Wird für den FileBrowser als Pfad benötigt
      logo: require('@/assets/images/png/NetworkOperatorLogos/Truphone-Logo.png'), // Logo
      title: 'Truphone GmbH',
      street: 'Mergenthalerallee 79-81',
      location: '65760 Eschborn',
      website: 'www.truphone.com',
      url: 'https://www.truphone.com',
      phone1_text: 'Kunden-Hotline',
      phone1: '0152 90000707 ',
      phone2_text: '',
      phone2: '',
      phone3_text: '',
      phone3: '',
      phone4_text: '',
      phone4: '',
      phone5_text: '',
      phone5: '',
      team_phone: '09286 9404-480',
      team_mail: 'ab@epsilon-telecom.de',
      message1:
        'Truphone wurde 2006 mit dem Ziel gegründet die Konnektivität zu Mobilfunkdiensten zu verbessern. Seitdem hat Truphone in die Entwicklung des weltweit ersten wirklich globalen Netzwerks durch eine eigene Netzwerkinfrastruktur investiert. Die patentierte Technologie bedient mittlerweile mehr als 3.500 multinationale Unternehmen in 196 Ländern.',
      role: 'TP'
    },
    Plusnet: {
      id: 'Plusnet', // Muss einmalig sein (kann frei ausgedacht sein, wird aber in der URL als Parameter angezeigt)
      caption: 'Plusnet', // Wird als Text angezeigt
      fileBrowserPath: 'Plusnet', // Wird für den FileBrowser als Pfad benötigt
      logo: require('@/assets/images/png/NetworkOperatorLogos/Plusnet_Logo.png'), // Logo
      title: 'Plusnet GmbH',
      street: 'Mathias-Brüggen-Str. 55',
      location: '50829 Köln',
      website: 'www.plusnet.de',
      url: 'https://www.plusnet.de',
      phone1_text: 'Kunden-Hotline',
      phone1: '0800 7722375 ',
      phone2_text: 'SUPPORT/SERVICE',
      phone2: '0800 5895277',
      phone3_text: '',
      phone3: '',
      phone4_text: '',
      phone4: '',
      phone5_text: '',
      phone5: '',
      team_phone: '09286 9404-470',
      team_mail: 'ab@epsilon-telecom.de',
      message:
        'Die Plusnet GmbH, die rund 400 Mitarbeiterinnen und Mitarbeiter an zehn Standorten in Deutschland zählt, ist ein deutsches Telekommunikationsunternehmen mit eigener bundesweiter Netzinfrastruktur und jahrzehntelanger Erfahrung im Betrieb sämtlicher Breitband-Technologien. 180 Vertriebspartner und Reseller sowie 150 ISP vermarkten für 25.000 Geschäftskunden in Deutschland komplette Lösungen und Produkte.',
      links: {
        link1: {
          text: 'K-TEL Partnernet',
          link: 'http://www.ktel.cloud'
        }
      },
      role: 'KTEL'
    },
    VERSATEL: {
      id: 'VERSATEL', // Muss einmalig sein (kann frei ausgedacht sein, wird aber in der URL als Parameter angezeigt)
      caption: 'VERSATEL', // Wird als Text angezeigt
      fileBrowserPath: 'VERSATEL', // Wird für den FileBrowser als Pfad benötigt
      logo: require('@/assets/images/png/NetworkOperatorLogos/1und1-Versatel-Logo.png'), // Logo
      title: '1&1 Versatel GmbH',
      street: 'Aroser Allee 78',
      location: '13407 Berlin',
      website: 'www.1und1.net',
      url: 'https://www.1und1.net',
      phone1_text: 'Kunden-Hotline',
      phone1: '0800 8040200 ',
      phone2_text: '',
      phone2: '',
      phone3_text: '',
      phone3: '',
      phone4_text: '',
      phone4: '',
      phone5_text: '',
      phone5: '',
      team_phone: '09286 9404-470',
      team_mail: 'ab@epsilon-telecom.de',
      message:
        '47.000 km eines der größten und leistungsfähigsten Glasfasernetze Deutschlands, vertreten in über 250 deutschen Städten mit eigener Netzinfrastruktur und mehr als 50.000 implementierte Geschäftskunden-Lösungen kann die 1&1 Versatel vorweisen. Mehr als 1.000 Mitarbeiter sorgen dafür, dass maximale Sicherheit und höchste Qualität von 1&1 Versatel garantiert werden.',
      links: {
        link1: {
          text: 'K-TEL Partnernet',
          link: 'http://www.ktel.cloud'
        }
      },
      role: 'KTEL'
    },
    COLT: {
      id: 'COLT', // Muss einmalig sein (kann frei ausgedacht sein, wird aber in der URL als Parameter angezeigt)
      caption: 'COLT', // Wird als Text angezeigt
      fileBrowserPath: 'COLT', // Wird für den FileBrowser als Pfad benötigt
      logo: require('@/assets/images/png/NetworkOperatorLogos/Colt-Logo.png'), // Logo
      title: 'Colt Technology Services GmbH',
      street: 'Gervinusstr. 18-22',
      location: '60322 Frankfurt am Main',
      website: 'www.colt.de',
      url: 'https://www.colt.de',
      phone1_text: 'Support-Hotline',
      phone1: '0800 26584636 ',
      phone2_text: '',
      phone2: '',
      phone3_text: '',
      phone3: '',
      phone4_text: '',
      phone4: '',
      phone5_text: '',
      phone5: '',
      team_phone: '09286 9404-470',
      team_mail: 'ab@epsilon-telecom.de',
      message:
        'Mit über 23.000 Endkunden, darunter einige der weltweit größten Unternehmen, ist das Partnerprogramm der Colt eines der größten und erfolgreichsten der Branche. Colt ist Innovationsführer beim Thema Software-Defined Networking (SDN) und Network Functions Virtualization (NFV). mehr als 900 Rechenzentren weltweit sowie mehr als 27.500 Gebäude sind bereits direkt an das Colt IQ Network angeschlossen.',
      links: {
        link1: {
          text: 'K-TEL Partnernet',
          link: 'http://www.ktel.cloud'
        }
      },
      role: 'KTEL'
    }
  },
  getProductByType(type) {
    const keys = Object.keys(this.products);
    for (let i = 0; i < keys.length; i++) {
      if (this.products[keys[i]].type === type) {
        return this.products[keys[i]];
      }
    }

    return null;
  },
  convertSalutationIntToStr(value) {
    if (!value) return '';
    if (typeof value !== 'number') {
      value = this.convertSalutationStrToInt(value);
    }
    if (value == 1) return 'Herr';
    if (value == 2) return 'Frau';
    if (value == 3) return 'Firma';
    if (value == 5) return 'Eheleute';
    return '';
  },
  convertSalutationStrToInt(value) {
    if (!value) return 0;
    if (typeof value === 'number') {
      if (value < 0 || value > 5 || value == 4) return 0;
      return value;
    }
    value = `${value}`; // to string
    value = value.toLowerCase().trim();
    if (value == '1' || value == 'herr' || value == 'mr') return 1;
    if (value == '2' || value == 'frau' || value == 'mrs') return 2;
    if (value == '3' || value == 'firma' || value == 'company') return 3;
    if (value == '5' || value == 'couple' || value == 'eheleute') return 5;
    return 0;
  },
  fillNationalities() {
    const nationalities = [];
    nationalities.push({
      value: 'DE',
      label: 'Deutsch'
    });
    nationalities.push({
      value: 'AT',
      label: 'Österreich'
    });
    nationalities.push({
      value: 'CH',
      label: 'Schweiz'
    });
    nationalities.push({
      value: '',
      label: 'Nicht gesetzt'
    });

    return nationalities;
  },
  convertStringToBoolean(value, errorReturnValue = false) {
    if (typeof value === 'boolean') {
      return value;
    }
    if (value === 'false') {
      return false;
    }
    if (value === 'true') {
      return true;
    }
    return errorReturnValue;
  },
  convertBooleanToString(value, errorReturnValue = 'false') {
    if (typeof value === 'string') {
      return value;
    }
    if (value === true) {
      return 'true';
    }
    if (value === false) {
      return 'false';
    }
    return errorReturnValue;
  },
  convertRadioToString(value, errorReturnValue = '0') {
    if (typeof value === 'string') {
      return value;
    }
    if (value === true) {
      return '1';
    }
    if (value === false) {
      return '0';
    }
    return errorReturnValue;
  },
  isNumber(value) {
    return !Number.isNaN(Number(value));
  },
  getFileTypeFromName(name) {
    return /(?:\.([^.]+))?$/.exec(name)[1];
  },
  getProcessedFileName(fn) {
    // remove underscores and dashs
    let processedFileName = fn.replace(/_/g, ' ');
    processedFileName = processedFileName.replace(/-/g, ' ');
    return processedFileName;
  },
  getStringDate(timestamp) {
    const date = new Date(parseInt(timestamp));
    let day;
    let month;
    if (date.getMonth() < 9) month = `0${date.getMonth() + 1}`;
    else month = date.getMonth() + 1;
    if (date.getDate() < 10) day = `0${date.getDate()}`;
    else day = date.getDate();
    const str = `${day}.${month}.${date.getFullYear()}`;
    return str;
  },
  getFileSize(s) {
    if (s < 1024) return `${s.toFixed(2)} Byte`;

    const kb = s / 1024;
    if (kb < 1024) return `${kb.toFixed(2)} KB`;

    const mb = kb / 1024;
    if (mb < 1024) return `${mb.toFixed(2)} MB`;

    const gb = mb / 1024;
    return `${gb.toFixed(2)} GB`;
  },
  findError(data) {
    if (data === null || data === undefined) {
      return null;
    }
    if (data.responseText) {
      const content = data.responseText;
      data = JSON.parse(content);
    }
    if (data._errorMessage) {
      return data._errorMessage;
    }
    if (data.errormessage) {
      return data.errormessage;
    }
    if (data._errorString) {
      return data._errorString;
    }
    try {
      return JSON.stringify(data);
    } catch {
      return data;
    }
  },
  escapeHtml(s) {
    return String(s).replace(/[&<>"'`=/]/g, (str) => entityMap[str]);
  },
  linkClick(href, target = null, attributes = {}) {
    const element = document.createElement('a');
    element.setAttribute('href', href);
    Object.keys(attributes).forEach((attr) => {
      element.setAttribute(attr, attributes[attr]);
    });
    if (target) element.target = target;
    element.click();
  },
  dataConverter(data) {
    let birthday = new Date(Number(data));
    let day;
    let month;
    // console.log("gebdatum: " + birthday.getMonth());
    if (birthday.getMonth() < 9) month = `0${birthday.getMonth() + 1}`;
    else month = birthday.getMonth() + 1;

    if (birthday.getDate() < 10) day = `0${birthday.getDate()}`;
    else day = birthday.getDate();
    birthday = `${day}.${month}.${birthday.getFullYear()}`;
    return birthday;
  }
};
