<template>
  <header>
    <nav class="navbar navbar-expand-xl bg-primary">
      <div class="container-fluid text-nowrap">
        <router-link to="/my-portal" class="navbar-brand">
          <Icon
            v-if="active_app == 'GNC'"
            name="logoGammaWhite"
            class="mx-3 my-1 logo-size" />
          <Icon
            v-if="active_app == 'ETC'"
            name="logoEtcWhite"
            class="mx-3 my-1 logo-size" />
        </router-link>

        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarToggler"
          aria-controls="navbarToggler"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div id="navbarToggler" class="collapse navbar-collapse">
          <ul v-if="currentUser" class="navbar-nav me-auto">
            <li v-if="hasRole(RolesAdmin)" class="nav-item">
              <router-link to="/etc/profile" class="nav-link">
                Meine Daten
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/my-portal" exact class="nav-link py-3 px-4">
                {{ $t('header.navigation.myPortal') }}
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{ name: 'EtcDocumentsNetworkOperators' }"
                class="nav-link py-3 px-4">
                Dokumente
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{ name: 'EtcTicketOverview' }"
                class="nav-link py-3 px-4">
                Ticketübersicht
              </router-link>
            </li>
            <li v-if="hasRole(RolesAkademy)" class="nav-item">
              <router-link
                :to="{ name: 'EtcAcademyOverview' }"
                class="nav-link py-3 px-4">
                Akademie 360°
              </router-link>
            </li>
            <li class="nav-item">
              <a class="nav-link py-3 px-4" href @click.prevent="logOut">
                {{ $t('header.navigation.logout') }}
              </a>
            </li>
          </ul>

          <ul v-if="!currentUser" class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <router-link to="/" class="nav-link py-3 px-4">
                {{ $t('header.navigation.login') }}
              </router-link>
            </li>
          </ul>

          <div
            v-if="currentUser && active_app == 'ETC'"
            class="align-items-center d-flex position-relative flex-grow-1">
            <div
              v-click-outside="clickOutside"
              class="search input-group ms-auto me-auto me-xl-0 my-2 my-xl-0">
              <input
                v-model="searchInput"
                type="text"
                class="form-control border-end-0 searchInput"
                :placeholder="$t('header.documentSearch')"
                @keyup.enter="search"
                @focus="searchFocus" />
              <div class="input-group-text search-button" @click="search">
                <Icon name="search" class="search-icon" />
              </div>
              <div
                v-if="showQuickSearch"
                class="quicksearch-box"
                autocomplete="off">
                <Loader
                  ref="searchLoader"
                  :background-spec="{ mode: 'blurred' }">
                  <div class="quicksearch-content">
                    <SearchListElement
                      v-for="(item, i) in quickSearchResults"
                      :key="'quicksearch-results-' + i"
                      class="row border-bottom py-2 hover-style"
                      :file="item" />
                  </div>
                </Loader>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import Icon from '@/components/utility/Icon.vue';
import AuthService from '@/services/auth.service.js';
import DocumentService from '@/services/document.service.js'; // TODO: find a way to remove circular dependencies
import debounce from '@/helper/debounce.js';
import Loader from '@/components/utility/Loader.vue';
import SearchListElement from '../elements/SearchListElement.vue';

export default {
  name: 'Header',
  components: {
    Icon,
    SearchListElement,
    Loader
  },
  data() {
    return {
      active_app: '',
      searchInput: '',
      RolesAdmin: ['PORTAL_ETC_PORTAL_ADMIN', 'PORTAL_EMPLOYEE'],
      RolesAkademy: ['PORTAL_CHEFRED', 'PORTAL_CMS_CHEFRED', 'PORTAL_USER'],
      quickSearchResults: [],
      shouldShowQuickSearch: false,

      // search only when typing stops to prevent spamming
      debouncedSearch: debounce(
        () => {
          this.$refs.searchLoader?.setLoading(true);
          DocumentService.search(this.searchInput, (res) => {
            this.$refs.searchLoader?.setLoading(false);
            if (this.searchInput != '') {
              this.quickSearchResults = res;
            }
          });
        },
        500,
        false
      )
    };
  },
  computed: {
    showQuickSearch() {
      return this.shouldShowQuickSearch;
    },
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  watch: {
    searchInput(newSearchInput, oldSearchInput) {
      if (newSearchInput !== oldSearchInput && newSearchInput !== '') {
        this.debouncedSearch();
      } else if (newSearchInput == '') {
        this.quickSearchResults = [];
      }
    }
  },

  mounted() {
    this.active_app = process.env.VUE_APP_ACTIVE_APPLICATION;
  },
  created() {
    this.$eventBus.$on('showDialog', (response) => {
      this.handleRequestError(response);
    });
  },
  methods: {
    clickOutside() {
      this.shouldShowQuickSearch = false;
      this.searchInput = '';
      this.quickSearchResults = [];
    },
    searchFocus() {
      this.shouldShowQuickSearch = true;
    },
    search() {
      if (
        this.searchInput.trim() == '' ||
        this.$route.query.search == this.searchInput
      ) {
        return;
      }
      this.$router.push({
        name: 'search',
        query: { search: this.searchInput }
      });
    },
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/');
    },
    hasRole(allowedRoles) {
      return this.$store.getters['auth/hasRole'](allowedRoles);
    },
    handleRequestError(response) {
      let text = `Fehler ${response.statusCode}`;
      let title = 'Unbekannter Fehler';
      let action = () => {};
      response.statusCode;
      switch (response.statusCode) {
        case 900:
          title = 'Account deaktiviert';
          text =
            'Der Account wurde deaktiviert. Bitte wende dich an den Support';
          break;
        case 901:
          title = 'Password zurücksetzen!';
          text =
            'Das Passwort muss zurückgesetzt werden. Es wird zur entsprechenden Seite weitergeleitet';
          action = () => this.$router.push('/reset-password');
          break;
        case 902:
          title = 'AGBs akzeptieren';
          text =
            'Wir haben unsere AGB aktualisiert. Diese müssen erneut akzeptiert werden';
          action = () => this.$router.push('/terms-of-use');
          break;
      }
      this.$swal
        .fire({
          icon: 'warning',
          title,
          text,
          confirmButtonText: 'OK'
        })
        .then(() => {
          action.apply();
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/custom.scss';

header {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 800;
  font-size: 12.5px;

  .quicksearch-box {
    position: absolute;
    top: 38px;
    z-index: 100;
    width: 100%;
    background-color: $white;
    max-height: 500px;
  }

  .quicksearch-content {
    overflow-y: auto;
    max-height: 500px;
  }

  .navbar {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .nav-link,
  .nav-link:focus {
    font-weight: 600;
    height: 100%;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    &.router-link-active {
      background-color: $secondary;
      color: $white;

      &:hover {
        color: $black;
      }
    }

    &:hover {
      background-color: $light;

      > svg {
        fill: $black;
      }
    }
  }

  .search {
    max-width: 800px;
    input:focus {
      box-shadow: none;
      border-color: $gray-300;
    }

    .search-button {
      cursor: pointer;
      background-color: $white;
      &:hover {
        background-color: $secondary;
        svg {
          fill: $white;
        }
      }
    }
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .search-icon {
    width: 19px;
    height: auto;
  }

  .logo-size {
    width: 96px;
    height: auto;
  }

  .usercheck-icon-size {
    width: 22px;
    height: auto;
  }
}
</style>
