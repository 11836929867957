import { apiUrl } from '@/const.js';
import dataHelper from '@/helper/dataHelper.js';
import { sendAjaxRequest } from './ajax.service.js';
import fileService from './file.service.js';

let store;
export function init(globalStore) {
  store = globalStore;
}

const DocumentService = {
  search(searchString, dataCallback) {
    const url = '/public/filebrowser/';
    const data = {};
    data._action = 'search';
    data.search = searchString;
    data.ep =
      'Unity_Media,Plusnet,Epsilon,colt,versatel,isec7,congstar,telefonica,vodafone,wertgarantie,Truephone,telekom,unitymedia,arealcontrol,O2_Business,Epsilon_Fusion_IOT,MMV_Leasing,Arealcontrol,Wireless_Logic';
    const fileList = [];
    sendAjaxRequest(url, 'GET', data).then(
      (data) => {
        fileList.splice(0);

        for (let k = 0; k < data.results.length; k++) {
          switch (data.results[k].type) {
            case 'repository':
              break;
            case 'folder':
              break;
            default:
              let d = data.results[k];
              d.sort = `zzz${d.name}`;
              if (
                d.description &&
                d.description.length > 0 &&
                d.description.substr(0, 1) == '#'
              ) {
                const lines = d.description.split('\n');
                while (
                  lines.length > 0 &&
                  lines[0].length > 0 &&
                  lines[0].substr(0, 1) == '#'
                ) {
                  const instr = lines[0].substr(1).split(':');
                  switch (instr[0]) {
                    case 'sort':
                      d.sort = instr[1].trim();
                      break;
                  }
                  lines.splice(0, 1);
                }
                d.description = lines.join('\n');
              }
              if (d.modified != '0') {
                d.modified_ = d.modified;

                const modified = new Date(parseInt(d.modified, 10));

                let day;
                let month;
                if (modified.getMonth() < 9)
                  month = `0${modified.getMonth() + 1}`;
                else month = modified.getMonth() + 1;
                if (modified.getDate() < 10) day = `0${modified.getDate()}`;
                else day = modified.getDate();
                d.modified = `${day}.${month}.${modified.getFullYear()}`;
              } else d.modified = '0';
              if (d && d.fileurl) {
                let { fileurl } = d;
                let query = '';
                const p = fileurl.indexOf('?');
                if (p > 0) {
                  query = fileurl.substr(p);
                  fileurl = fileurl.substr(0, p);
                }
                const position = d.path.indexOf(':');
                d.netz = d.path.slice(0, position);
                fileurl =
                  encodeURIComponent(encodeURIComponent(d.path)) + query;
                d.fileurl = fileurl;
                d.browserUrl = `${apiUrl}/public/filebrowser/${fileurl}?_action=download&_jwt_token=${store.state.auth.user.token}`;
              }

              d.index = k;
              fileList.push(d);
          }
        }
        // execute callback with received data;
        dataCallback(fileList);
      },
      (error) => {
        this.$toastr.e('Fehler', error);
        this.showErrorMsg = error.errMsg;
      }
    );
  },
  downloadFile(file) {
    if (file.properties.shortcuturl === '') {
      // Sonderlösung für den Einstiegspunkt 'Provisionen'. Dort muss der Pfad doppelt entcodet werden. Namen mit Leerzeichen versursachen sonst das Problem, dass die Dateien nicht angezeigt bzw. nicht ge�ffnet werden k�nnen.
      // if(this.start=="Provisionslisten:")
      fileService.getFileDownloadLink(file.path).then((ret) => {
        const url = ret.link;
        dataHelper.linkClick(url, undefined, { download: file.name });
      });
    } else {
      window.open(file.properties.shortcuturl, '_blank').focus();
    }
  },
  openFile(file) {
    this.downloadFile(file);
    return true;
  }
};
export default DocumentService;
