import TicketService from '@/services/ticket.service.js';

const initState = {
  tickets: null,
  selectedTicket: null,
  selectedTicketAttachments: null,
  isTicketAttachmentLoading: false
};

export default {
  namespaced: true,
  state: initState,
  actions: {
    getTickets({ commit, dispatch }, payload = {}) {
      return TicketService.getTicketList(payload).then(
        (tickets) => {
          tickets.sort((a, b) => b.foreignid - a.foreignid);

          commit('SET_TICKETS', tickets);
          return Promise.resolve(tickets);
        },
        (error) => {
          dispatch('reset');
          return Promise.resolve(error);
        }
      );
    },
    setSelectedTicket({ commit, dispatch }, ticketId) {
      commit('SET_SELECTED_TICKET_ATTACHMENTS', null);
      if (ticketId) {
        commit('SET_ATTACHMENT_LOADING_STATE', true);
        return TicketService.getTicket(ticketId).then(
          (ticket) => {
            commit('SET_SELECTED_TICKET', ticket);
            if (ticket.value5 !== 'etcCallBackRequest') {
              dispatch(
                'setSelectedTicketAttachments',
                ticket.foreignid
              ).finally(() => {
                commit('SET_ATTACHMENT_LOADING_STATE', false);
              });
            } else {
              commit('SET_ATTACHMENT_LOADING_STATE', false);
            }
            return Promise.resolve(ticket);
          },
          (error) => {
            dispatch('reset');
            commit('SET_ATTACHMENT_LOADING_STATE', false);
            return Promise.reject(error);
          }
        );
      }
      commit('SET_SELECTED_TICKET', null);
    },
    setSelectedTicketAttachments({ commit }, ticketForeignId) {
      return TicketService.getTicketAttachments(ticketForeignId).then(
        (selectedTicketAttachments) => {
          commit('SET_SELECTED_TICKET_ATTACHMENTS', selectedTicketAttachments);
          return Promise.resolve(selectedTicketAttachments);
        },
        (error) => {
          commit('SET_SELECTED_TICKET_ATTACHMENTS', null);
          return Promise.resolve(error);
        }
      );
    },
    addNote({ dispatch }, payload = {}) {
      // TODO: check if actions not using commit data directly are actually useful in stores
      if (payload.ticketId && payload.note) {
        return TicketService.addNote(payload.ticketId, payload.note).then(
          (note) => {
            dispatch('setSelectedTicket', payload.ticketId);
            return Promise.resolve(note);
          },
          (error) => {
            dispatch('reset');
            return Promise.resolve(error);
          }
        );
      }
    },
    reopenTicket({ dispatch }, payload = {}) {
      if (payload.ticketId && payload.reason) {
        return TicketService.reopenTicket(
          payload.ticketId,
          payload.reason
        ).then(
          (response) => {
            console.log(`response:${response}`);
            dispatch('setSelectedTicket', payload.ticketId);
            return Promise.resolve(response);
          },
          (error) => Promise.resolve(error)
        );
      }
    },
    reset({ commit }) {
      commit('RESET_TICKET');
    }
  },
  mutations: {
    SET_TICKETS(state, tickets) {
      state.tickets = tickets;
    },
    SET_SELECTED_TICKET(state, selectedTicket) {
      state.selectedTicket = selectedTicket;
    },
    SET_SELECTED_TICKET_ATTACHMENTS(state, selectedTicketAttachments) {
      state.selectedTicketAttachments = selectedTicketAttachments;
    },
    RESET_TICKET(state) {
      Object.assign(state, initState);
    },
    SET_ATTACHMENT_LOADING_STATE(state, isTicketAttachmentLoading) {
      state.isTicketAttachmentLoading = isTicketAttachmentLoading;
    }
  }
};
